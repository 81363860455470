import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { isEmpty } from "../assets/Utils";
import Header from "../layouts/Header";

const StreetsIndex = () => {
  const [searchStreet, setSearchStreet] = useState("");
  const [dataResponseStreets, setDataResponseStreets] = useState([]);
  const currentCity = useSelector((state) => state.cityReducer);
  const googleMapsApiKey = "AIzaSyDlAgJTgLU0PcPaHQkQTwEZmPECV3Vz0gg";

  useEffect(() => {
    if (searchStreet.length > 2) {
      axios
        .get(
          `https://api-adresse.data.gouv.fr/search/?q=${searchStreet}&postcode=${currentCity.postalCode}&type=street`
        )
        .then((response) => {
          setDataResponseStreets(response.data.features);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setDataResponseStreets([]);
    }
  }, [searchStreet]);

  const onChangeSearchStreet = (e) => {
    let searchingStreet = e.target.value;
    searchingStreet = searchingStreet.replace(/ /g, "+");
    searchingStreet = searchingStreet
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    setSearchStreet(searchingStreet);
  };

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };

  return (
    <div className="streets-index-container">
      <div className="streets-index-page-container" style={cityBackground}>
        <Header navPath="decouverte" stringBtn="Retourner découvrir" />
        <div className="streets-index-content">
          <h1>{currentCity.name} : Index des rues</h1>
          <form autoComplete="off">
            <label htmlFor="street-search">Rechercher une rue</label>
            <input
              type="text"
              id="street-search"
              name="street-search"
              onChange={onChangeSearchStreet}
              placeholder="Nom de la rue"
            ></input>
          </form>
          <section className="results-streets">
            <h2>Résultats :</h2>
            {!isEmpty(dataResponseStreets) && (
              <i>Cliquez sur une rue pour vous y conduire !</i>
            )}
            <ul>
              {!isEmpty(dataResponseStreets) ? (
                dataResponseStreets.map((street) => {
                  return (
                    <li key={street.properties.id}>
                      <a
                        href={`https://www.google.com/maps?q=${street.properties.name}, ${currentCity.postalCode} ${currentCity.name}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {street.properties.name} <br />
                        {currentCity.postalCode} {currentCity.name}
                      </a>
                    </li>
                  );
                })
              ) : searchStreet.length <= 2 ? (
                <li>
                  <p>Commencez à écrire le nom d'une rue.</p>
                </li>
              ) : (
                <li>
                  <p>Aucun résultat, vérifiez l'orthographe de la rue.</p>
                </li>
              )}
            </ul>
          </section>
        </div>
        <section className="iframe-container">
          <iframe
            title="Google Maps"
            src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${currentCity.postalCode}+${currentCity.name}`}
            allowFullScreen
          ></iframe>
        </section>
      </div>
    </div>
  );
};

export default StreetsIndex;
