import axios from "axios";

export const SET_PICTURE_PARTNER = "SET_PICTURE_PARTNER";
export const SET_LOGO_PARTNER = "SET_LOGO_PARTNER";
export const SET_VIDEO_PARTNER = "SET_VIDEO_PARTNER";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const RESET = "RESET";
export const REORDER_ELEMENTS = "REORDER_ELEMENTS";
export const INITIALIZE_UPLOADS = "INITIALIZE_UPLOADS";

export const setPicturePartner = async (data) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/upload/partner", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: SET_PICTURE_PARTNER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setLogoPartner = async (data) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/upload/partner", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: SET_LOGO_PARTNER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setVideoPartner = async (data) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/upload/partner", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: SET_VIDEO_PARTNER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteElement = async (id) => {
  return async (dispatch) => {
    return axios
      .delete("https://api.lesbonsplans.pro" + "/upload/partner/" + id)
      .then((res) => {
        dispatch({ type: DELETE_ELEMENT, payload: id });
      })
      .catch((err) => console.log(err));
  };
};

export const resetReducer = () => {
  return async (dispatch) => {
    dispatch({ type: RESET });
  };
};

export const reorderElements = (data) => ({
  type: REORDER_ELEMENTS,
  payload: data,
});

export const initializeUploads = (uploads) => {
  return {
    type: INITIALIZE_UPLOADS,
    payload: uploads,
  };
};
