import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../components/LogoCity";
import { useDispatch, useSelector } from "react-redux";
import { getCity } from "../actions/city.action";
import { getContentCity } from "../actions/contentCity.action";
import { isEmpty } from "../assets/Utils";

const Home = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const dataMessage = useSelector((state) => state.messageReducer);
    const partners = useSelector((state) => state.partnerReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [hasPartnerCity, setHasPartnerCity] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("contentCityMemo")) {
            localStorage.removeItem("contentCityMemo");
            navigate("/");
            window.location.reload();
        }
    }, [navigate]);

    useEffect(() => {
        const checkPartnerCity = async () => {
            const result = await verifPartnerCity();
            setHasPartnerCity(result);
        };
        checkPartnerCity();
    }, [currentCity, partners]);

    const onClickRedirectCities = async () => {
        dispatch(getCity());
        dispatch(getContentCity());
        window.location.reload();
    };

    const verifPartnerCity = async () => {
        if (isEmpty(currentCity)) {
            await dispatch(getCity());
        }
        return partners.some((partner) => partner.city === currentCity._id && partner.isActive);
    }

    const getMessageById = (id) => {
        return dataMessage.find((message) => message._id === id);
    }

    const isMessageRecent = (messageId) => {
        const message = getMessageById(messageId);
        if (!message) return false;

        const messageTime = new Date(message.datePublication).getTime();
        const now = Date.now();
        const timeDifference = now - messageTime;

        return timeDifference >= 0 && timeDifference < 24 * 60 * 60 * 1000;
    };

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    return (
        <div className="home-container nav-page-container" style={cityBackground}>
            <section className="logo-container">
                <Logo />
            </section>
            <section className="nav-container">
                <nav className="nav-categories">
                    <ul>
                        <li>
                            <NavLink to="/" onClick={() => onClickRedirectCities()}>
                                <i className="bx bxs-home"></i>Villes
                            </NavLink>
                        </li>
                        {hasPartnerCity && (
                            <li>
                                <NavLink to="/categories">
                                    <i className="bx bxs-heart"></i>Partenaires
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.calendarLink) && (
                            <li>
                                <NavLink to="/agenda">
                                    <i className="bx bxs-calendar"></i>Agenda
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/decouverte">
                                <i className="bi bi-search-heart"></i>Découverte
                            </NavLink>
                        </li>
                        {!isEmpty(currentCity.socialMedia) && (
                            <li>
                                <NavLink to="/reseaux-sociaux">
                                    <i className="bx bxs-chat"></i>Réseaux sociaux
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/contact">
                                <i className="bx bxs-contact"></i>Contact
                            </NavLink>
                        </li>
                        {!isEmpty(contentCurrentCity.guidePrestigeLink) && (
                            <li>
                                <NavLink to="/GuidePrestige">
                                    <i className="bx bxs-gift"></i>Les Bons Plans
                                </NavLink>
                            </li>
                        )}
                        {!isEmpty(contentCurrentCity.messages) && (
                            <li>
                                {contentCurrentCity.messages.some(message => isMessageRecent(message)) ? (
                                <NavLink to="/nos-messages">
                                    <i className="bx bxs-envelope" style={{ color:"red" }}></i>Nos Messages

                                </NavLink>
                                    ):(<NavLink to="/nos-messages">
                                    <i className="bx bxs-envelope"></i>Nos Messages

                                </NavLink>)}
                            </li>
                        )}
                    </ul>
                </nav>
            </section>
        </div>
    );
};

export default Home;