import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";

// Redux
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import {getCity} from "./actions/city.action";
import {getPartner} from "./actions/partner.action";
import {getUser} from "./actions/user.action";
import {getCategories} from "./actions/category.action";
import {getContentCity} from "./actions/contentCity.action";
import {getGuidedTour} from "./actions/guidedTour.action";
import {getMessage} from "./actions/message.action";

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").then(
            (registration) => {
                console.log(
                    "ServiceWorker registration successful with scope: ",
                    registration.scope
                );
            },
            (error) => {
                console.log("ServiceWorker registration failed: ", error);
            }
        );
    });
}

export const store = configureStore({
    reducer: rootReducer,
    devTools: false, // A changer en false pour la prod
});

store.dispatch(getCity());
store.dispatch(getPartner());
store.dispatch(getUser());
store.dispatch(getCategories());
store.dispatch(getContentCity());
store.dispatch(getGuidedTour());
store.dispatch(getMessage());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

export default store;
