import {
  DELETE_AUDIO_GUIDED_TOUR,
  RESET_AUDIO_GUIDED_TOUR,
  SET_AUDIO_GUIDED_TOUR,
} from "../actions/contentGuidedTour.action";

const initialState = [];

export default function contentGuidedTourReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUDIO_GUIDED_TOUR:
      return action.payload.fileId;
    case DELETE_AUDIO_GUIDED_TOUR:
      return initialState;
    case RESET_AUDIO_GUIDED_TOUR:
      return initialState;
    default:
      return state;
  }
}
