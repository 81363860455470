import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useLocation } from "react-router";
import { isEmpty } from "../assets/Utils";
import axios from "axios";
import { useSelector } from "react-redux";

const GuidedTour = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const location = useLocation();
  const currentGuidedTour = location.state;
  const [languagesAudio, setLanguagesAudio] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("FRA");
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    // Vérifie si l'appareil est un Apple
    const userAgent = window.navigator.userAgent.toLowerCase();
    const appleDevices = ["iphone", "ipad", "macintosh"];
    setIsAppleDevice(appleDevices.some((device) => userAgent.includes(device)));

    // Récupère la liste des langues
    if (isEmpty(allLanguages)) {
      axios
          .get("https://restcountries.com/v3.1/all")
          .then((res) => setAllLanguages(res.data));
    }
    // Récupère les langues disponibles pour l'audio
    if (!isEmpty(currentGuidedTour.contentAudio)) {
      const languages = Object.keys(currentGuidedTour.contentAudio);
      setLanguagesAudio(languages);
    }
  }, []);

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
        currentCity.picture
    }) fixed center bottom/cover`,
  };

  const handleOpenAudio = (langCca3) => {
    const audioUrl =
        "https://api.lesbonsplans.pro" +
        "/upload/upload/audio/" +
        currentGuidedTour.contentAudio[langCca3];
    window.open(audioUrl, "_blank");
  };

  return (
      <div
          className="guided-tour-container"
          style={cityBackground}
          onContextMenu={(e) => e.preventDefault()}
      >
        <Header
            navPath={"visites-guidees"}
            stringBtn={"Retour aux visites guidées"}
        />
        <h1>Visites Guidées</h1>
        <section className="guided-tour">
          <div className="infos-guided-tour">
            <img
                src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/upload/image/" +
                    currentGuidedTour.picture
                }
                alt={`Image de "${currentGuidedTour.name}"`}
            />
            <h2>{currentGuidedTour.name}</h2>
            <a
                href={`https://www.google.com/maps?q=${currentGuidedTour.coordinates[0]},${currentGuidedTour.coordinates[1]}`}
                target="_blank"
                rel="noreferrer"
            >
              Se rendre sur le lieu
            </a>
          </div>
          <div className="audio-guided-tour">
            <h3>Cliquez sur votre langue</h3>
            <p>Click on your language</p>
            <div className="languages-list">
              {allLanguages
                  .filter((lang) => languagesAudio.includes(lang.cca3))
                  .map((lang) => (
                      <img
                          className={selectedLanguage === lang.cca3 ? "selected" : ""}
                          src={lang.flags.png}
                          alt={"Drapeau " + lang.translations.fra.common}
                          key={lang.cca3}
                          onClick={() => setSelectedLanguage(lang.cca3)}
                      />
                  ))}
            </div>
            <div className="audio-selected-language">
              {!isEmpty(selectedLanguage) && (
                  <>
                    {isAppleDevice ? (
                        <button
                            onClick={() => handleOpenAudio(selectedLanguage)}
                            className="download-button"
                        >
                          ▶ Ouvrir l'audio
                        </button>
                    ) : (
                        <audio
                            controls
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/upload/audio/" +
                                currentGuidedTour.contentAudio[selectedLanguage]
                            }
                        >
                          Your browser does not support the
                          <code>audio</code> element.
                        </audio>
                    )}
                  </>
              )}
            </div>
          </div>
        </section>
      </div>
  );
};

export default GuidedTour;
