import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { NavLink } from "react-router-dom";
import { setCurrentCategory } from "../actions/category.action";

const CategoryListEntry = ({ category }) => {
    const currentCity = useSelector((state) => state.cityReducer);
    const dispatch = useDispatch();
  const onClickRedirect = (currentCategory) => {
    dispatch(setCurrentCategory(currentCategory));
  };
  return (
    <div className="category-entry">
      <NavLink to={`/nos-partenaires/${currentCity.subDomain}/${category.subDomain}`} onClick={() => onClickRedirect(category)}>
        <h2>{category.name}</h2>
      </NavLink>
    </div>
  );
};

export default CategoryListEntry;
