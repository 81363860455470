import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MdContentCopy} from "react-icons/md";
import Select from 'react-select';
import {
  deleteElement,
  reorderElements,
  resetReducer,
  setLogoPartner,
  setPicturePartner,
  setVideoPartner,
} from "../actions/partnerUploads.action";
import { isEmpty} from "../assets/Utils";
import {updatePartner} from "../actions/partner.action";
import CategorySelector from "../components/CategorySelector";
import AdminSortingPartnerUploadsUpdate from "../components/AdminSortingPartnerUploadsUpdate";


const AdminUpdatePartner = ({ partner, closePopup }) => {
  const [deletedUploadIds, setDeletedUploadIds] = useState([]);
  const dataCity = useSelector((state) => state.cityReducer);
  const dataCategory = useSelector((state) => state.categoryReducer);
  const contentUpdatePartner = useSelector(
      (state) => state.partnerUploadsReducer
  );
  const [categoryUpdatePartner, setCategoryUpdatePartner] = useState([]);
  const [socialMediaUpdatePartner, setSocialMediaUpdatePartner] = useState(
      partner.socialMedia
  );
  const [logoUpdatePartner, setLogoUpdatePartner] = useState(partner.logo);
  const [uploadsUpdatePartner, setUploadsUpdatePartner] = useState(
      partner.contentUploads
  );
  const [updatingPartner, setUpdatingPartner] = useState(partner);
  const [isFormValid, setIsFormValid] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(contentUpdatePartner.uploads)) {
      setUploadsUpdatePartner(contentUpdatePartner.uploads);
    }
  }, [contentUpdatePartner.uploads]);

  useEffect(() => {
    let tempDefaultCategories = [];
    partner.category.forEach((category) => {
      tempDefaultCategories.push({
        label: category,
        value: category,
      });
    });
    setCategoryUpdatePartner(tempDefaultCategories);
    dispatch(reorderElements(uploadsUpdatePartner));
  }, []);

  useEffect(() => {
    if (!isEmpty(contentUpdatePartner.logo)) {
      setLogoUpdatePartner(contentUpdatePartner.logo);
    }
  }, [contentUpdatePartner.logo]);

  const handleIsFormValid = () => {
    if (!isEmpty(updatingPartner.name)) {
      setIsFormValid(true);
      return;
    }
    setIsFormValid(false);
  };

  const handleCopyUrl = () => {
    const urlField = document.getElementById("url-partner");
    urlField.select();
    document.execCommand("copy");
  };

  const onChangeCategorySelector = (values) => {
    setCategoryUpdatePartner(values);
    let tempCategories = [];
    values.forEach((category) => {
      tempCategories.push(category.value);
    });
    setUpdatingPartner({
      ...updatingPartner,
      category: tempCategories,
    });
    handleIsFormValid();
  };

  const handleSocialMediaChanged = (e) => {
    const input = e.target;
    if (isEmpty(input.value)) {
      const tempSocialMedia = {...socialMediaUpdatePartner};
      delete tempSocialMedia[input.name];
      setSocialMediaUpdatePartner(tempSocialMedia);
      return;
    }
    setSocialMediaUpdatePartner({
      ...socialMediaUpdatePartner,
      [input.name]: input.value,
    });
    setUpdatingPartner({...updatingPartner, socialMedia: socialMediaUpdatePartner});
    handleIsFormValid();
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    let formDataLogo = new FormData();
    formDataLogo.append("file", file);
    dispatch(await deleteElement(logoUpdatePartner));
    dispatch(await setLogoPartner(formDataLogo));
    handleIsFormValid();
  };

  const handlePictureUpload = async (e) => {
    const file = e.target.files[0];
    let formDataPicture = new FormData();
    formDataPicture.append("file", file);
    dispatch(await setPicturePartner(formDataPicture));
    setUploadsUpdatePartner([...uploadsUpdatePartner, { id: file.name, type: "picture" }]);
    handleIsFormValid();
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    let formDataVideo = new FormData();
    formDataVideo.append("file", file);
    dispatch(await setVideoPartner(formDataVideo));
    handleIsFormValid();
  };

  const resetUpdatePopup = async ()  => {
    const newPicture = newPictureUpload();
    for (const upload of newPicture) {
      dispatch(await deleteElement(upload));
    }
    dispatch(resetReducer());
    closePopup({});
  };

  const getCityById = (id) => {
    return dataCity.find((city) => city._id === id);
  };

  const getCategoryByName = (name) => {
    return dataCategory.find((category) => category.name === name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    const form=e.target;
    let updatesPartner = {};
    let isChanged = false;

    if (updatingPartner.name !== partner.name) {
      updatesPartner = { ...updatesPartner, name: updatingPartner.name };
      isChanged = true;
    }
    if (updatingPartner.phoneNumber !== partner.phoneNumber) {
      updatesPartner = {
        ...updatesPartner,
        phoneNumber: updatingPartner.phoneNumber,
      };
      isChanged = true;
    }
    if (updatingPartner.emailAddress !== partner.emailAddress) {
      updatesPartner = {
        ...updatesPartner,
        emailAddress: updatingPartner.emailAddress,
      };
      isChanged = true;
    }
    if (updatingPartner.website !== partner.website) {
      updatesPartner = { ...updatesPartner, website: updatingPartner.website };
      isChanged = true;
    }
    if (updatingPartner.reservation !== partner.reservation) {
        updatesPartner = {
            ...updatesPartner,
            reservation: updatingPartner.reservation,
        };
        isChanged = true;
    }
    if (updatingPartner.meeting !== partner.meeting) {
        updatesPartner = {
            ...updatesPartner,
            meeting: updatingPartner.meeting,
        };
        isChanged = true;
    }
    if (updatingPartner.shopping !== partner.shopping) {
        updatesPartner = {
            ...updatesPartner,
            shopping: updatingPartner.shopping,
        };
        isChanged = true;
    }
    if (updatingPartner.city !== partner.city) {
      updatesPartner = { ...updatesPartner, city: updatingPartner.city };
      isChanged = true;
    }
    if (updatingPartner.streetNumber !== partner.streetNumber) {
      updatesPartner = {
        ...updatesPartner,
        streetNumber: updatingPartner.streetNumber,
      };
      isChanged = true;
    }
    if (updatingPartner.streetName !== partner.streetName) {
      updatesPartner = {
        ...updatesPartner,
        streetName: updatingPartner.streetName,
      };
      isChanged = true;
    }


    const latitudeValue = form["latitude-partner"].value.replace(",", ".");
    const longitudeValue = form["longitude-partner"].value.replace(",", ".");
    const latitude = latitudeValue ? parseFloat(latitudeValue) : null;
    const longitude = longitudeValue ? parseFloat(longitudeValue) : null;
    if (latitude !== partner.coordinates[0] || longitude !== partner.coordinates[1]) {
      updatesPartner = { ...updatesPartner, coordinates: [latitude, longitude] };
      isChanged = true;
    }

    if (updatingPartner.contentText !== partner.contentText) {
      updatesPartner = {
        ...updatesPartner,
        contentText: updatingPartner.contentText,
      };
      isChanged = true;
    }


    if (updatingPartner.category !== partner.category) {
      if(updatingPartner.category.length === 0) {
        window.alert("Veuillez sélectionner au moins une catégorie");
        return;
      }
      updatesPartner = { ...updatesPartner, category: updatingPartner.category };
      isChanged = true;
    }
    if (socialMediaUpdatePartner !== partner.socialMedia) {
      updatesPartner.socialMedia = socialMediaUpdatePartner;
      isChanged = true;
    }
    if (logoUpdatePartner !== partner.logo) {
      updatesPartner = { ...updatesPartner, logo: logoUpdatePartner };
      isChanged = true;
    }
    if (uploadsUpdatePartner !== partner.contentUploads) {
      for (const upload1 of partner.contentUploads
          .filter(upload => !uploadsUpdatePartner.some(updatedUpload => updatedUpload.id === upload.id))) {
        dispatch(await deleteElement(upload1.id));
      }

      updatesPartner = {
        ...updatesPartner,
        contentUploads: uploadsUpdatePartner
      };

      isChanged = true;
    }

    if(updatingPartner.subDomain !== partner.subDomain) {
      updatesPartner = {
        ...updatesPartner,
        subDomain: updatingPartner.subDomain,
      };
      isChanged = true;
    }

    if(updatingPartner.url !== partner.url) {
        updatesPartner = {
            ...updatesPartner,
            url: updatingPartner.url,
        };
        isChanged = true;
    }


    if (isChanged) {
      updatesPartner = { ...updatesPartner, _id: partner._id };
      dispatch(updatePartner(updatesPartner));
    }
    dispatch(resetReducer());
    closePopup({});
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      bottom: '5px',
      border: '2px solid black',
      borderRadius: '10px',
      boxShadow: 'none',
      '&:focus': {
        outline: 'none',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: '15px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: '15px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: '2px solid black',
      borderRadius: '10px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightgray' : 'white',
      color: 'black',
      fontSize: '15px',
    }),
  };

  const newPictureUpload = () => {
    const newUploads = contentUpdatePartner.uploads.filter(upload =>
        !partner.contentUploads.some(partnerUpload => partnerUpload.id === upload.id)
    );
    return newUploads.map(upload => upload.id);
  };

  const handleDeletedIdsChange = (ids) => {
    setDeletedUploadIds(ids);
    const updatedUploads = uploadsUpdatePartner.filter(upload => !ids.includes(upload.id));
    setUploadsUpdatePartner(updatedUploads);
  };

  useEffect(() => {
    if (updatingPartner.subDomain && updatingPartner.category && updatingPartner.city) {
      const category = updatingPartner.category && updatingPartner.category[0] ? getCategoryByName(updatingPartner.category[0]) : "";
      const subDomainCity = getCityById(updatingPartner.city).subDomain;
      const newUrl = `https://www.lesbonsplans.pro/partenaires/${subDomainCity}/${category.subDomain}/${updatingPartner.subDomain}`;

      setUpdatingPartner((prevState) => ({
        ...prevState,
        url: newUrl,
      }));
    }
  }, [updatingPartner.subDomain, updatingPartner.category, updatingPartner.city]);



  return (
      <section className="popup update-partner">
        <div className="popup-update-container">
          <div className="popup-update-partner-header">
            <h2>Mise à jour d'un partenaire</h2>
            <div className="cancel-button" onClick={resetUpdatePopup}>
              <p>Annuler</p>
              <i className="bx bx-x-circle"></i>
            </div>
          </div>
          <form
              id="form-update-partner"
              autoComplete="off"
              onSubmit={handleSubmit}
          >
            <section className="update-partner-setup">
              <div className="info-update-partner">
                <h3>Informations</h3>
                <div className="info-update-partner-container">
                  <label htmlFor="name">Nom : </label>
                  <input
                      type="text"
                      id="name"
                      defaultValue={partner.name}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          name: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Nom du partenaire"
                      required
                  />
                  <label htmlFor="tel">Téléphone : </label>
                  <input
                      type="tel"
                      id="tel"
                      name="tel"
                      defaultValue={partner.phoneNumber}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          phoneNumber: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Numéro de téléphone"
                  />
                  <label htmlFor="email">Email : </label>
                  <input
                      type="email"
                      id="email"
                      name="email"
                      defaultValue={partner.emailAddress}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          emailAddress: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Adresse e-mail"
                  />
                  <label htmlFor="website">Site web : </label>
                  <input
                      type="url"
                      id="website"
                      name="website"
                      defaultValue={partner.website}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          website: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Site web du partenaire"
                      pattern="https?://.+"
                  />
                </div>
                <div className="link-update-partner">
                  <label htmlFor="reservation">Réservation : </label>
                  <input
                      type="url"
                      id="reservation"
                      name="reservation"
                      defaultValue={partner.reservation}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          reservation: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Site web de réservation"
                      pattern="https?://.+"
                  />
                  <label htmlFor="meeting">Rendez-vous : </label>
                  <input
                      type="url"
                      id="meeting"
                      name="meeting"
                      defaultValue={partner.meeting}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          meeting: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Site web de rendez-vous"
                      pattern="https?://.+"
                  />
                  <label htmlFor="shopping">Boutique en ligne : </label>
                  <input
                      type="url"
                      id="shopping"
                      name="shopping"
                      defaultValue={partner.shopping}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          shopping: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Site web de la boutique en ligne"
                      pattern="https?://.+"
                  />
                </div>
                <div className="adress-update">
                  <div className="city-select-container">
                    <label htmlFor="city">Ville : </label>
                    <Select
                        id="city"
                        name="city"
                        styles={customStyles}
                        options={dataCity.map(city => ({value: city.name, label: city.name}))}
                        defaultValue={{value: getCityById(partner.city).name, label: getCityById(partner.city).name}}
                        onChange={(e) => {
                          setUpdatingPartner({
                            ...updatingPartner,
                            city: dataCity.find(city => city.name === e.value)._id,
                          });
                          handleIsFormValid();
                        }}
                    />
                  </div>
                  <label htmlFor="street-number">Complément d'adresse : </label>
                  <input
                      type="text"
                      id="street-number"
                      name="street-number"
                      defaultValue={partner.streetNumber}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          streetNumber: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Complément d'adresse"
                  />
                  <label htmlFor="street-name">Numéro et rue : </label>
                  <input
                      type="text"
                      id="street-name"
                      name="street-name"
                      defaultValue={partner.streetName}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          streetName: e.target.value,
                        });
                        handleIsFormValid();
                      }}
                      placeholder="Numéro et rue"
                  />
                </div>
                <div className="coordinates-update">
                  <label htmlFor="latitude-partner">Latitude :</label>
                  <input
                      type="text"
                      id="latitude-partner"
                      name="latitude-partner"
                      defaultValue={partner.coordinates[0] !== null ? partner.coordinates[0] : ""}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          coordinates: [
                            e.target.value,
                            updatingPartner.coordinates[1],
                          ],
                        });
                        handleIsFormValid();
                      }}
                  />
                  <label htmlFor="longitude-partner">Longitude :</label>
                  <input
                      type="text"
                      id="longitude-partner"
                      name="longitude-partner"
                      defaultValue={partner.coordinates[1] !== null ? partner.coordinates[1] : ""}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          coordinates: [
                            e.target.value,
                            updatingPartner.coordinates[0],
                          ],
                        });
                        handleIsFormValid();
                      }}
                  />
                </div>
                <div className="url-update">
                  <label htmlFor="name">Sous-Domain : </label>
                  <input
                      type="text"
                      id="subDomain"
                      defaultValue={partner.subDomain}
                      onChange={(e) => {
                        setUpdatingPartner({
                          ...updatingPartner,
                          subDomain: e.target.value,
                        })
                        handleIsFormValid();
                      }}

                      placeholder="Sous-domaine du partenaire"
                      pattern="[a-z0-9\-]+"
                  />
                  <label htmlFor="url-partner">Url du partenaire : </label>
                  <input
                      type="text"
                      id="url-partner"
                      name="url-partner"
                      value={updatingPartner.url}
                      placeholder="Url du partenaire"
                      readOnly
                  />
                  <button type="button" onClick={handleCopyUrl}>
                    <MdContentCopy style={{fontSize: '20px'}}/>
                  </button>
                </div>
              </div>
              <div className="description-update-partner">
                <label htmlFor="description" id="label-description">
                  Description du partenaire :
                </label>
                <textarea
                    id="description"
                    defaultValue={partner.contentText}
                    onChange={(e) => {
                      setUpdatingPartner({
                        ...updatingPartner,
                        contentText: e.target.value,
                      });
                      handleIsFormValid();
                    }}
                />
              </div>
              <div className="categories-update-partner">
                <h3>Catégories</h3>
                <CategorySelector
                    onChange={onChangeCategorySelector}
                    value={categoryUpdatePartner}
                />
              </div>
              <div className="social-media-update-partner">
                <h3>Réseaux sociaux</h3>
                <div className="social-media-update-partner-container">
                  <div className="google-update social-media-update">
                    <label htmlFor="google">Google :</label>
                    <input
                        type="text"
                        id="google"
                        name="google"
                        defaultValue={partner.socialMedia ? partner.socialMedia.google : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.google.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="facebook-update social-media-update">
                    <label htmlFor="facebook">Page Facebook :</label>
                    <input
                        type="text"
                        id="facebook"
                        name="facebook"
                        defaultValue={partner.socialMedia ? partner.socialMedia.facebook : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.facebook.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="instagram-update social-media-update">
                    <label htmlFor="instagram">Instagram :</label>
                    <input
                        type="text"
                        id="instagram"
                        name="instagram"
                        defaultValue={partner.socialMedia ? partner.socialMedia.instagram : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.instagram.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="twitter-update social-media-update">
                    <label htmlFor="twitter">Twitter :</label>
                    <input
                        type="text"
                        id="twitter"
                        name="twitter"
                        defaultValue={partner.socialMedia ? partner.socialMedia.twitter : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.twitter.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="pinterest-update social-media-update">
                    <label htmlFor="pinterest">Pinterest :</label>
                    <input
                        type="text"
                        id="pinterest"
                        name="pinterest"
                        defaultValue={partner.socialMedia ? partner.socialMedia.pinterest : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.pinterest.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="tiktok-update social-media-update">
                    <label htmlFor="tiktok">TikTok :</label>
                    <input
                        type="text"
                        id="tiktok"
                        name="tiktok"
                        defaultValue={partner.socialMedia ? partner.socialMedia.tiktok : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.tiktok.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="youtube-update social-media-update">
                    <label htmlFor="youtube">Youtube :</label>
                    <input
                        type="text"
                        id="youtube"
                        name="youtube"
                        defaultValue={partner.social ? partner.socialMedia.youtube : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.youtube.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="linkedin-update social-media-update">
                    <label htmlFor="linkedin">Linkedin :</label>
                    <input
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        defaultValue={partner.socialMedia ? partner.socialMedia.linkedin : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.linkedin.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                  <div className="tripadvisor-update social-media-update">
                    <label htmlFor="tripadvisor">Tripadvisor :</label>
                    <input
                        type="text"
                        id="tripadvisor"
                        name="tripadvisor"
                        defaultValue={partner.socialMedia ? partner.socialMedia.tripadvisor : ""}
                        pattern="https?://.+"
                        title="Exemple: https://www.tripadvisor.com/..."
                        onChange={handleSocialMediaChanged}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="update-partner-content">
              <h3>Contenu</h3>
              <div className="update-partner-content-container">
                <label htmlFor="logo-content">Modifier le logo</label>
                <input
                    type="file"
                    id="logo-content"
                    name="file-logo"
                    onChange={handleLogoUpload}
                    hidden
                />
                <label htmlFor="picture-content">Ajouter une photo</label>
                <input
                    type="file"
                    id="picture-content"
                    name="file-picture"
                    onChange={handlePictureUpload}
                    hidden
                />
                <label htmlFor="video-content">Ajouter une vidéo</label>
                <input
                    type="file"
                    id="video-content"
                    name="file-video"
                    onChange={handleVideoUpload}
                    hidden
                />
              </div>
            </section>
            {!isEmpty(logoUpdatePartner) && (
                <section className="uploaded-logo">
                  <h3>Logo actuel</h3>
                  <img
                      src={
                          "https://api.lesbonsplans.pro" +
                          "/upload/partner/image/" +
                          logoUpdatePartner
                      }
                      alt="Logo ajouté"
                      title="Logo actuel du partenaire"
                      width={200}
                  />
                </section>
            )}
            {!isEmpty(uploadsUpdatePartner) && (
                <AdminSortingPartnerUploadsUpdate
                    onDeleteIdsChange={handleDeletedIdsChange}
                    uploads={uploadsUpdatePartner}
                />            )}
            <input
                id={isFormValid ? "valid" : "invalid"}
                type="submit"
                value="Mettre à jour le partenaire"
            />
          </form>
        </div>
      </section>
  );
};

export default AdminUpdatePartner;
