import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import logoPj from "../assets/images/logo-pj-notxt.jpg";

const PracticalGuideRedirect = () => {
  const currentCity = useSelector((state) => state.cityReducer);
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);

  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
      currentCity.picture
    }) fixed center bottom/cover`,
  };
  return (
    <div className="practical-guide-redirect-container" style={cityBackground}>
      <Header />
      <h1>Découverte</h1>
      <div className="nav-guides">
        <ul>
          <li>
            <NavLink to="/index-rues">
              <i className="bx bxs-directions"></i>Plan/Index des rues
            </NavLink>
          </li>
          <li>
            <NavLink to="/pages-jaunes">
              <img src={logoPj} alt="Logo Pages Jaunes" width={100} />
              Pages jaunes
            </NavLink>
          </li>
          {!isEmpty(contentCurrentCity.weatherLink) && (
            <li>
              <NavLink to="/meteo">
                <i className="bx bxs-cloud"></i>La météo
              </NavLink>
            </li>
          )}
          {!isEmpty(contentCurrentCity.infoCityLink) && (
            <li>
              <NavLink to="/infos-pratiques">
                <i className="bx bxs-info-circle"></i>Infos pratiques
              </NavLink>
            </li>
          )}
          {!isEmpty(contentCurrentCity.liveCamLink) && (
            <li>
              <NavLink to="/webcam">
                <i className="bx bxs-video"></i>La ville en direct
              </NavLink>
            </li>
          )}
          {!isEmpty(contentCurrentCity.cityNewsLink) && (
            <li>
              <NavLink to="/actualites">
                <i className="bx bxs-news"></i>Actualités
              </NavLink>
            </li>
          )}
          {(!isEmpty(contentCurrentCity.cityMagLink) || !isEmpty(contentCurrentCity.magazines)) && (
              <li>
                <NavLink to="/magazines">
                  <i className="bx bx-book"></i>Magazines
                </NavLink>
              </li>
          )}
          {!isEmpty(contentCurrentCity.guidedTour) && (
            <li>
              <NavLink to="/visites-guidees">
                <i className="bx bxs-user-voice"></i>Visites guidées
              </NavLink>
            </li>
          )}

        </ul>
      </div>
    </div>
  );
};

export default PracticalGuideRedirect;
