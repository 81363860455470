import {
  GET_GUIDED_TOUR,
  GET_GUIDED_TOUR_BY_ID,
  SET_GUIDED_TOUR,
  UPDATE_GUIDED_TOUR,
  DELETE_GUIDED_TOUR,
} from "../actions/guidedTour.action";

const initialState = [];

export default function guidedTourReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GUIDED_TOUR:
      return action.payload;
    case GET_GUIDED_TOUR_BY_ID:
        return action.payload;
    case SET_GUIDED_TOUR:
      return action.payload;
    case UPDATE_GUIDED_TOUR:
      return state.map((guidedTour) =>
          guidedTour._id === action.payload._id ? action.payload : guidedTour
      );
    case DELETE_GUIDED_TOUR:
      return state.filter((guidedTour) => guidedTour._id !== action.payload);
    default:
      return state;
  }
}
