import React, { useState } from 'react';

const ImageGallery = ({ images, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = (event) => {
        event.stopPropagation();
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = (event) => {
        event.stopPropagation();
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="image-gallery" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={onClose}>X</button>
            <button className="prev-button" onClick={handlePrev}>{"<"}</button>
            <img src={images[currentIndex].url} alt={`Image ${currentIndex + 1}`} />
            <button className="next-button" onClick={handleNext}>{">"}</button>
            <div className="bottom-controls">
                <button className="bottom-prev-button" onClick={handlePrev}>{"<"}</button>
                <button className="bottom-next-button" onClick={handleNext}>{">"}</button>
            </div>
        </div>
    );
};

export default ImageGallery;