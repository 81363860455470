import React, {useState} from 'react';
import {isEmpty} from "../assets/Utils";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";
import {useDispatch, useSelector} from "react-redux";
import {
    resetReducer,
    setPicturePartner,
} from "../actions/partnerUploads.action";
import {setMagazine} from "../actions/magazine.action";
import {deletePictureGuidedTour} from "../actions/pictureGuidedTour.action";
import {pictureCompressor} from "../assets/utils/pictureCompressor";

const AdminNewMagazine = ({closePopup}) => {
    const contentNewMagazine = useSelector(
        (state) => state.partnerUploadsReducer
    );
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch();


    const handleIsFormValid = () => {
        const form = document.getElementById("form-new-magazine");
        if (form.checkValidity()) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };


    const handlePictureUpload = async (e) => {
        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            try {
                let formDataPicture = await pictureCompressor(files[i]);
                dispatch(await setPicturePartner(formDataPicture));
            } catch (error) {
                console.error("Erreur lors de la compression de l'image :", error);
            }
        }
        handleIsFormValid();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        let uploadsNewMagazine = contentNewMagazine.uploads;
        let newMagazine = {
            name: form["name-magazine"].value,
            numberMag: form["number-magazine"].value,
            pictures: uploadsNewMagazine,
        };
        dispatch(setMagazine(newMagazine));
        dispatch(resetReducer());
        form.reset();
        closePopup();
    }

    const handleDeletePicture = () => {
        contentNewMagazine.uploads.forEach((picture) => {
            dispatch(deletePictureGuidedTour(picture.id));
        });
    }

    return (
        <section className="popup popup-magazine">
            <div className="popup-content-magazine-container">
                <div className="popup-magazine-header">
                    <h2>Créer un magazine</h2>
                    <div className="cancel-button" onClick={() => {
                        handleDeletePicture();
                        closePopup();
                    }}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form id="form-new-magazine" autoComplete="off" onSubmit={handleSubmit}>
                    <div className="label-info-magazine-container">
                        <label htmlFor="name-magazine">Nom du magazine</label>
                        <input
                            type="text"
                            id="name-magazine"
                            name="name-magazine"
                            placeholder="LE GRAU DU ROI ET SES BONS PLANS"
                            required
                        />
                        <label htmlFor="number-magazine">Numéro du magazine</label>
                        <input
                            type="text"
                            id="number-magazine"
                            name="number-magazine"
                            placeholder="Mag 1"
                            required
                        />
                    </div>
                    <section className="magazine-image">
                        <h3>Contenu</h3>
                        <div className="magazine-content-container">
                            <label htmlFor="picture-content">Ajouter une photo</label>
                            <input
                                type="file"
                                id="picture-content"
                                onChange={handlePictureUpload}
                                hidden
                                multiple
                            />
                        </div>
                    </section>
                    {!isEmpty(contentNewMagazine) && (
                        <AdminSortingPartnerUploads/>
                    )}
                    <div className="magazine-footer">
                        <input
                            id={isFormValid ? "valid" : "invalid"}
                            type="submit"
                            value="Créer le magazine"
                        />
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AdminNewMagazine;