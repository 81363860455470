import {
  DELETE_PICTURE_GUIDED_TOUR,
  RESET_PICTURE_GUIDED_TOUR,
  SET_PICTURE_GUIDED_TOUR,
} from "../actions/pictureGuidedTour.action";

const initialState = [];

export default function pictureGuidedTourReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PICTURE_GUIDED_TOUR:
      return action.payload.fileId;
    case DELETE_PICTURE_GUIDED_TOUR:
      return initialState;
    case RESET_PICTURE_GUIDED_TOUR:
      return initialState;
    default:
      return state;
  }
}
