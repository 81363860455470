import axios from "axios";

export const GET_USER = "GET_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SET_USER = "SET_USER";

export const getUser = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/admin")
      .then((res) => {
        dispatch({ type: GET_USER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getUserById = (userId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        "https://api.lesbonsplans.pro" + `/admin/${userId}`
      );
      dispatch({ type: GET_USER_BY_ID, payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
};

export const createUser = (newUser) => {
  return async (dispatch) => {
    try {
      await axios.post("https://api.lesbonsplans.pro" + "/admin", newUser);
      dispatch({ type: SET_USER, payload: newUser });
    } catch (err) {
      console.log(err);
    }
  };
};
