import {
    GET_MESSAGE,
    SET_MESSAGE,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
} from "../actions/message.action";

const initialState = [];

export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGE:
            return action.payload;
        case SET_MESSAGE:
            return [action.payload, ...state];
        case UPDATE_MESSAGE:
            return state.map((message) =>
                message._id === action.payload._id ? action.payload : message
            );
        case DELETE_MESSAGE:
            return state.filter((message) => message._id !== action.payload);
        default:
            return state;
    }
}