import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteElementCity, resetReducerCityUploads,
  setBackgroundCity,
  setLogoCity,
} from "../actions/cityUploads.action";
import { setCity } from "../actions/city.action";
import { isEmpty, generatesubDomain } from "../assets/Utils";
import {pictureCompressor} from "../assets/utils/pictureCompressor";

const AdminNewCity = () => {
  const dataCityManagement = useSelector((state) => state.cityReducer);
  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("");
  const [socialMediaNewCity, setSocialMediaNewCity] = useState({});
  const dataUploadCity = useSelector((state) => state.cityUploadsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(dataUploadCity.logo)) {
      setLogo(dataUploadCity.logo.fileId);
    }
  }, [dataUploadCity.logo]);

  useEffect(() => {
    if (!isEmpty(dataUploadCity.background)) {
      setBackground(dataUploadCity.background.fileId);
    }
  }, [dataUploadCity.background]);

  const handleLogoChanged = async (e) => {
    const file = e.target.files[0];
    let formDataLogo = await pictureCompressor(file);
    dispatch(await deleteElementCity(logo));
    dispatch(await setLogoCity(formDataLogo));
  };

  const handleBackgroundChanged = async (e) => {
    const file = e.target.files[0];
    let formDataPicture = await pictureCompressor(file);
    dispatch(await deleteElementCity(background));
    dispatch(await setBackgroundCity(formDataPicture));
  };

  const handleSocialMediaChanged = (e) => {
    const input = e.target;
    if (isEmpty(input.value)) {
      const tempSocialMedia = { ...socialMediaNewCity };
      delete tempSocialMedia[input.name];
      setSocialMediaNewCity(tempSocialMedia);
      return;
    }
    setSocialMediaNewCity({
      ...socialMediaNewCity,
      [input.name]: input.value,
    });
  };

  const getCoordinates = async (city) => {
    const apiKey = "3e4b994188404404a254eb09e60b8d1b";
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      city
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry;
        return [lat, lng];
      }
      console.log("Coordinates not found for the city:", city);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    let cityCoordinates = await getCoordinates(form[1].value);
    if (!cityCoordinates) {
      console.log("Coordinates not found for the city:", form[1].value);
      cityCoordinates = [0, 0];
      return;
    }
    const logoID = dataUploadCity.logo.fileId;
    const backgroundID = dataUploadCity.background.fileId;
    const newCity = {
      country: form[0].value,
      name: form[1].value,
      postalCode: form[2].value,
      coordinates: cityCoordinates,
      acronym: form[3].value,
      logo: logoID,
      picture: backgroundID,
      socialMedia: socialMediaNewCity,
      subDomain: generatesubDomain(form[1].value, dataCityManagement),
      isActive: false,
    };
    dispatch(setCity(newCity));
    setLogo("");
    setBackground("");
    setSocialMediaNewCity({});
    dispatch(resetReducerCityUploads());
    form.reset();
  };


  return (
    <section className="new-city">
      <h2>Création d'une nouvelle ville</h2>
      <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
        <div className="info-new-city">
          <h3>Informations</h3>
          <label htmlFor="country">Pays :</label>
          <input
            type="text"
            id="country"
            name="country"
            defaultValue="France"
            placeholder="Pays"
            required
          />
          <label htmlFor="name">Nom :</label>
          <input type="text" id="name" placeholder="Nom de la ville" required />
          <label htmlFor="postalCode">Code postal :</label>
          <input
            type="text"
            id="postalCode"
            placeholder="Code postal"
            required
          />
          <label htmlFor="acronym">Acronyme :</label>
          <input
            type="text"
            id="acronym"
            pattern="[A-Z]{2,4}"
            title="Acronyme de la ville (2 à 4 lettres en majuscules)"
            placeholder="Acronyme"
          />
        </div>
        <div className="files-upload">
          <div className="input-container-uploads">
            <h3>Fichiers</h3>
            <div className="picture-container">
              <label htmlFor="picture">
                {isEmpty(dataUploadCity.background)
                  ? "Importer une image d'arrière plan"
                  : "Modifier l'image d'arrière plan"}
              </label>
              <input
                type="file"
                name="file"
                id="picture"
                accept="image/*"
                onChange={handleBackgroundChanged}
                hidden
              />
            </div>
            <div className="logo-container">
              <label htmlFor="logo">
                {isEmpty(dataUploadCity.logo) ? "Importer un logo" : "Modifier le logo"}
              </label>
              <input
                type="file"
                name="file"
                id="logo"
                accept="image/*"
                onChange={handleLogoChanged}
                hidden
              />
            </div>
          </div>
          <div className="render-uploads-new-city">
            {!isEmpty(dataUploadCity.background) && (
              <div className="background-new-city">
                <h4>Image d'arrière plan</h4>
                <img
                  src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/city/image/" +
                    dataUploadCity.background.fileId
                  }
                  alt="Arrière plan"
                />
              </div>
            )}
            {!isEmpty(dataUploadCity.logo) && (
              <div className="logo-new-city">
                <h4>Logo</h4>
                <img
                  src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/city/image/" +
                    dataUploadCity.logo.fileId
                  }
                  alt="Logo"
                />
              </div>
            )}
          </div>
        </div>
        <div className="social-media-new-city">
          <h3>Réseaux sociaux</h3>
          <label htmlFor="google">Google :</label>
          <input
              type="text"
              id="google"
              name="google"
              pattern="https?://.+"
              title="Exemple: https://www.google.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="facebookp">Page Facebook :</label>
          <input
              type="text"
              id="facebookp"
              name="facebookp"
              pattern="https?://.+"
              title="Exemple: https://www.facebook.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="facebookg">Groupe Facebook :</label>
          <input
              type="text"
              id="facebookg"
              name="facebookg"
              pattern="https?://.+"
              title="Exemple: https://www.facebook.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="instagram">Instagram :</label>
          <input
              type="text"
              id="instagram"
              name="instagram"
              pattern="https?://.+"
              title="Exemple: https://www.instagram.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="twitter">Twitter :</label>
          <input
              type="text"
              id="twitter"
              name="twitter"
              pattern="https?://.+"
              title="Exemple: https://www.twitter.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="pinterest">Pinterest :</label>
          <input
              type="text"
              id="pinterest"
              name="pinterest"
              pattern="https?://.+"
              title="Exemple: https://www.pinterest.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="tiktok">TikTok :</label>
          <input
              type="text"
              id="tiktok"
              name="tiktok"
              pattern="https?://.+"
              title="Exemple: https://www.tiktok.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="youtube">Youtube :</label>
          <input
              type="text"
              id="youtube"
              name="youtube"
              pattern="https?://.+"
              title="Exemple: https://www.youtube.com/..."
              onChange={handleSocialMediaChanged}
          />
          <label htmlFor="linkedin">Linkedin :</label>
          <input
              type="text"
              id="linkedin"
              name="linkedin"
              pattern="https?://.+"
              title="Exemple: https://www.linkedin.com/..."
              onChange={handleSocialMediaChanged}
          />
        </div>
        <input type="submit" value="Créer la ville"/>
      </form>
    </section>
  );
};

export default AdminNewCity;
