import React from "react";
import Header from "../layouts/Header";
import {useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";

const SocialMedia = () => {
    const currentCity = useSelector((state) => state.cityReducer);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };
    return (
        <div className="social-media-container" style={cityBackground}>
            <Header/>
            <h1>Réseaux Sociaux</h1>
            <section className="links-container">
                {!isEmpty(currentCity.socialMedia) && (
                    <ul>
                        {currentCity.socialMedia.google && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.google}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-google"></i>
                                    Google
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.facebookp && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.facebookp}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-facebook-circle"></i>
                                    Page Facebook
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.facebookg && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.facebookg}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-facebook-circle"></i>
                                    Groupe Facebook
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.instagram && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.instagram}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-instagram-alt"></i>
                                    Instagram
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.twitter && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.twitter}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-twitter"></i>X (Twitter)
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.tiktok && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.tiktok}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-tiktok"></i>
                                    TikTok
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.youtube && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.youtube}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-youtube"></i>
                                    Youtube
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.pinterest && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.pinterest}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-pinterest"></i>
                                    Pinterest
                                </a>
                            </li>
                        )}
                        {currentCity.socialMedia.linkedin && (
                            <li>
                                <a
                                    href={currentCity.socialMedia.linkedin}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <i className="bx bxl-linkedin-square"></i>
                                    Linkedin
                                </a>
                            </li>
                        )}
                    </ul>
                )}
                {isEmpty(currentCity.socialMedia) && (
                    <p>
                        Aucun réseau social n'a été renseigné pour cette ville pour le
                        moment.
                    </p>
                )}
            </section>
        </div>
    );
};

export default SocialMedia;
