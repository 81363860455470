import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../actions/user.action";

const AdminNewUser = () => {
  const dataCity = useSelector((state) => state.cityReducer);
  const dispatch = useDispatch();
  const [citiesNewUser, setCitiesNewUser] = useState([]);
  const [levelsNewUser, setLevelsNewUser] = useState([]);

  const handleCheckAllCities = (e) => {
    const checkboxes = document.getElementsByName("city-checkbox");
    if (e.target.checked) {
      checkboxes.forEach((checkbox) => {
        setCitiesNewUser(dataCity.map((city) => city._id));
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        setCitiesNewUser([]);
        checkbox.checked = false;
      });
    }
  };

  const handleAddCity = (e) => {
    if (e.target.checked) {
      setCitiesNewUser([...citiesNewUser, e.target.value]);
    } else {
      setCitiesNewUser(citiesNewUser.filter((item) => item !== e.target.value));
    }
  };

  const handleAddLevel = (e, level) => {
    if (e.target.checked) {
      setLevelsNewUser([...levelsNewUser, level]);
    } else {
      setLevelsNewUser(levelsNewUser.filter((item) => item !== level));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const newUser = {
      lastName: form[0].value,
      firstName: form[1].value,
      userName: form[2].value,
      password: form[3].value,
      assignedCities: citiesNewUser,
      adminLevels: levelsNewUser,
    };
    dispatch(createUser(newUser));
    form.reset();
  };
  return (
    <section className="new-user">
      <h2>Création d'un nouvel administrateur</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="info-new-user">
          <h3>Informations personnelles</h3>
          <input type="text" placeholder="Nom" autoComplete="off" required />
          <input type="text" placeholder="Prénom" autoComplete="off" required />
          <input
            type="text"
            placeholder="Nom d'utilisateur"
            autoComplete="off"
            required
          />
          <input
            type="password"
            placeholder="Mot de passe (provisoire)"
            autoComplete="off"
            required
          />
        </div>
        <div className="cities-new-user">
          <h3>Villes attitrées</h3>
          <input
            type="checkbox"
            id="all"
            onChange={(e) => handleCheckAllCities(e)}
            checked={citiesNewUser.length === dataCity.length}
            hidden
          />
          <label
            id={
              citiesNewUser.length === dataCity.length
                ? "checkedAllCities"
                : "uncheckedAllCities"
            }
            htmlFor="all"
          >
            Cocher toutes les villes
          </label>
          <div className="input-cities">
            {dataCity.map((city) => (
              <div key={city._id}>
                <input
                  type="checkbox"
                  name="city-checkbox"
                  id={city.acronym}
                  value={city._id}
                  onChange={(e) => handleAddCity(e)}
                  hidden
                />
                <label
                  id={citiesNewUser.includes(city._id) ? "cityChecked" : ""}
                  htmlFor={city.acronym}
                >
                  {city.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="admin-levels-new-user">
          <h3>Niveaux d'administration</h3>
          <div className="input-levels">
            <input
              type="checkbox"
              name="admin-level"
              id="admin1"
              onChange={(e) => handleAddLevel(e, 1)}
              hidden
            />
            <label
              id={levelsNewUser.includes(1) ? "levelChecked" : ""}
              htmlFor="admin1"
            >
              Administrateur global
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin2"
              onChange={(e) => handleAddLevel(e, 2)}
              hidden
            />
            <label
              id={levelsNewUser.includes(2) ? "levelChecked" : ""}
              htmlFor="admin2"
            >
              Administrateur ville
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin3"
              onChange={(e) => handleAddLevel(e, 3)}
              hidden
            />
            <label
              id={levelsNewUser.includes(3) ? "levelChecked" : ""}
              htmlFor="admin3"
            >
              Administrateur contenu
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin4"
              onChange={(e) => handleAddLevel(e, 4)}
              hidden
            />
            <label
              id={levelsNewUser.includes(4) ? "levelChecked" : ""}
              htmlFor="admin4"
            >
              Modérateur contenu
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin5"
              onChange={(e) => handleAddLevel(e, 5)}
              hidden
            />
            <label
              id={levelsNewUser.includes(5) ? "levelChecked" : ""}
              htmlFor="admin5"
            >
              Administrateur partenaire
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin6"
              onChange={(e) => handleAddLevel(e, 6)}
              hidden
            />
            <label
              id={levelsNewUser.includes(6) ? "levelChecked" : ""}
              htmlFor="admin6"
            >
              Modérateur partenaire
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin20"
              onChange={(e) => handleAddLevel(e, 20)}
              hidden
            />
            <label
              id={levelsNewUser.includes(20) ? "levelChecked" : ""}
              htmlFor="admin20"
            >
              Notifications
            </label>
            <input
              type="checkbox"
              name="admin-level"
              id="admin30"
              onChange={(e) => handleAddLevel(e, 30)}
              hidden
            />
            <label
              id={levelsNewUser.includes(30) ? "levelChecked" : ""}
              htmlFor="admin30"
            >
              Statistiques
            </label>
          </div>
        </div>
        <input type="submit" value="Créer un administrateur" />
      </form>
    </section>
  );
};

export default AdminNewUser;
