// src/pages/GuidedTourList.js
import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { NavLink } from "react-router-dom";
import { calculateDistance } from "../assets/utils/distance";

const GuidedTourList = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const currentCityContent = useSelector((state) => state.contentCityReducer);
    const dataGuidedTour = useSelector((state) => state.guidedTourReducer);
    const [guidedTourList, setGuidedTourList] = useState([]);
    const [userPosition, setUserPosition] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserPosition({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Erreur lors de la récupération de la position :", error);
                    setUserPosition(null);
                },
                options
            );
        } else {
            console.error("La géolocalisation n'est pas supportée par ce navigateur.");
            setUserPosition(null);
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(currentCityContent) && !isEmpty(dataGuidedTour)) {
            const guidedTourListTemp = dataGuidedTour.filter((tour) =>
                currentCityContent.guidedTour.includes(tour._id)
            );
            setGuidedTourList(guidedTourListTemp);
        }
    }, [currentCityContent, dataGuidedTour]);

    useEffect(() => {
        if (userPosition && !isEmpty(guidedTourList)) {
            const toursWithDistance = guidedTourList.map((tour) => {
                const distance = calculateDistance(
                    userPosition.lat,
                    userPosition.lon,
                    tour.coordinates[0],
                    tour.coordinates[1]
                );
                return { ...tour, distance };
            });

            if (JSON.stringify(toursWithDistance) !== JSON.stringify(guidedTourList)) {
                setGuidedTourList(toursWithDistance.sort((a, b) => a.distance - b.distance));
            }
        }
    }, [userPosition, guidedTourList]);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${currentCity.picture}) fixed center bottom/cover`,
    };

    return (
        <div className="guided-tour-list-container" style={cityBackground}>
            <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"} />
            <h1>Visites Guidées</h1>
            <section className="guided-tour-list">
                {!isEmpty(guidedTourList) &&
                    guidedTourList.map((tour) => (
                        <div className="guided-tour-list-entry" key={tour._id}>
                            <NavLink to={"/visite-guidee"} state={tour}>
                                <img
                                    src={
                                        "https://api.lesbonsplans.pro" +
                                        "/upload/upload/image/" +
                                        tour.picture
                                    }
                                    alt={"Image de la visite guidée " + tour.name}
                                />
                                <h2>{tour.name}</h2>
                                <div className="distance">
                                    <p>{tour.distance ? `${tour.distance} km` : "N/A"}</p>
                                </div>
                            </NavLink>
                        </div>
                    ))}
            </section>
        </div>
    );
};

export default GuidedTourList;