import HeaderAdmin from "../layouts/HeaderAdmin";
import React, {useEffect, useState} from "react";
import NavbarAdmin from "../components/NavbarAdmin";
import AdminNewMessage from "../layouts/AdminNewMessage";
import {isEmpty} from "../assets/Utils";
import {useDispatch, useSelector} from "react-redux";
import AdminSortingPartnerCitySelect from "../components/AdminSortingPartnerCitySelect";
import {getMessage} from "../actions/message.action";
import AdminUpdateMessage from "../layouts/AdminUpdateMessage";

const AdminMessages = () => {
    const [nameMessageSorting, setNameMessageSorting] = useState("");
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const dataMessage = useSelector((state) => state.messageReducer);
    const [sortingCityValue, setSortingCityValue] = useState([]);
    const [sortingCityName, setSortingCityName] = useState("");
    const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);
    const [filteredMessage, setFilteredMessage] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const dispatch = useDispatch();
    const [selectedElement, setselectedElement] = useState(null);
    const [popupUpdateMessage, setPopupUpdateMessage] = useState(false);

    const normalizeString = (string) => {
        return string
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    };

    const handleCitySelected = (value) => {
        setSortingCityValue(value);
    };

    useEffect(() => {
        if (!isEmpty(sortingCityValue)) {
            let tempValues = [];
            sortingCityValue.forEach((city) => {
                tempValues.push(city.label);
            });
            setSortingCityName(tempValues);
        } else {
            setSortingCityName("");
        }
    }, [sortingCityValue]);


    useEffect(() => {
        const tempFilteredData = dataMessage
            .filter((message) =>
                normalizeString(message.title).includes(
                    normalizeString(nameMessageSorting)
                )
            )
            .filter(
                (message) =>
                    isEmpty(sortingCityValue) ||
                    message.city.some((city) => {
                        return sortingCityName.includes(city);
                    })
            )
            
            .filter((message) => {
                const publicationDate = new Date(message.datePublication);
                const start = startDate ? new Date(startDate) : null;
                const end = endDate ? new Date(endDate) : null;
                return (
                    (!start || publicationDate >= start) &&
                    (!end || publicationDate <= end)
                );
            })
            .sort((a, b) => a.title.localeCompare(b.title));
        setFilteredMessage(tempFilteredData);
        setIsFilteredEmpty(isEmpty(tempFilteredData));
    }, [
        dataMessage,
        nameMessageSorting,
        sortingCityName,
        startDate,
        endDate
    ]);

    const handleMessageClick = (message) => {
        setselectedElement(message);
        setPopupUpdateMessage(true);
    };

    const handleRefreshPartners = () => {
        dispatch(getMessage());
        window.alert(
            "Tableau des messages actualisé.\nTous les messages sont à présent modifiables et activables."
        );
    };

    return (
        <div className="message-admin-container">
            <HeaderAdmin/>
            <section className="admin-content-container">
                <NavbarAdmin/>
                <div className="admin-content">
                    <AdminNewMessage/>
                    <section className="message-management">
                        <h2>Gestion des messages</h2>
                        <form>
                            <div className="name-select-message">
                                <label htmlFor="messageName">
                                    <h4>Titre du message :</h4>
                                </label>
                                <input
                                    type="text"
                                    name="messageName"
                                    id="messageName"
                                    value={nameMessageSorting}
                                    onChange={(e) => setNameMessageSorting(e.target.value)}
                                    placeholder="Titre du message"
                                />
                            </div>
                            <div className="others-select-options">
                                <h4>Choisir la ville :</h4>
                                <AdminSortingPartnerCitySelect
                                    onChange={handleCitySelected}
                                    value={sortingCityValue}
                                />
                                <h4>Date de publication</h4>
                                <div className="date-publication">
                                    <label htmlFor="startDate">De :</label>
                                    <input
                                        type="date"
                                        id="startDate"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <label htmlFor="endDate">À :</label>
                                    <input
                                        type="date"
                                        id="endDate"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                            </div>
                        </form>
                        <button className="btn-refresh" onClick={handleRefreshPartners}>
                            Actualiser <i className="bx bx-refresh"></i>
                        </button>
                        {isFilteredEmpty ? (
                            <p>
                                Aucun message ne correspond à votre recherche. Veuillez
                                réessayer.
                            </p>
                        ) : (
                            <section className="message-overview">
                                {filteredMessage.map((message) => (
                                    <div
                                        key={message._id}
                                        className="message"
                                        onClick={() => handleMessageClick(message)}
                                    >
                                        <img
                                            src={
                                                "https://api.lesbonsplans.pro/upload/partner/image/" +
                                                message.logo
                                            }
                                            alt="image visite"
                                        />
                                        <p>{message.title}</p>
                                    </div>
                                ))}
                            </section>
                        )}
                    </section>
                </div>
            </section>
            {popupUpdateMessage && selectedElement && (
                <AdminUpdateMessage
                    message={selectedElement}
                    closePopup={() => setPopupUpdateMessage(false)}
                />
            )}
        </div>
    )
}
export default AdminMessages;