import React from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDispatch, useSelector } from "react-redux";
import { CSS } from "@dnd-kit/utilities";
import {
  deleteElement,
  reorderElements,
} from "../actions/partnerUploads.action";

const SortableItem = ({ upload, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: upload.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className={
        upload.type === "picture"
          ? "picture-new-partner-container"
          : "video-new-partner-container"
      }
    >
      {upload.type === "picture" ? (
        <>
          <img
            src={`${"https://api.lesbonsplans.pro"}/upload/partner/image/${
              upload.id
            }`}
            alt="Photo ajoutée"
            width={150}
          />
          <i
            className="bx bx-x-circle"
            title="Supprimer la photo"
            onClick={() => onDelete(upload.id)}
          ></i>
        </>
      ) : (
        <>
          <video controls width={300}>
            <source
              src={`${"https://api.lesbonsplans.pro"}/upload/partner/video/${
                upload.id
              }`}
              type="video/mp4"
            />
          </video>
          <i
            className="bx bx-x-circle"
            title="Supprimer la vidéo"
            onClick={() => onDelete(upload.id)}
          ></i>
        </>
      )}
    </div>
  );
};

const AdminSortingPartnerUploads = () => {
  const dispatch = useDispatch();
  const contentNewPartner = useSelector((state) => state.partnerUploadsReducer);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = contentNewPartner.uploads.findIndex(
        (upload) => upload.id === active.id
      );
      const newIndex = contentNewPartner.uploads.findIndex(
        (upload) => upload.id === over.id
      );

      const newUploads = arrayMove(
        contentNewPartner.uploads,
        oldIndex,
        newIndex
      );
      dispatch(reorderElements(newUploads));
    }
  };

  const handleDelete = async (id) => {
    dispatch(await deleteElement(id));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <fieldset className="uploaded-files-container">
        <legend>Fichiers ajoutés (Photos + Vidéos)</legend>
        <SortableContext
          items={contentNewPartner.uploads.map((upload) => upload.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="uploaded-files">
            {contentNewPartner.uploads.map((upload) => (
              <SortableItem
                key={upload.id}
                upload={upload}
                onDelete={handleDelete}
              />
            ))}
          </div>
        </SortableContext>
      </fieldset>
    </DndContext>
  );
};

export default AdminSortingPartnerUploads;
