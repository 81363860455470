import React, {useEffect, useState} from "react";
import partage_safari from '../assets/images/partage_safari.png';
import partage_chrome from '../assets/images/partage_chrome.png';
import ajouteralecran from '../assets/images/ajouteralecran.jpg';
import ajouter from '../assets/images/ajouter.jpg';

const Modal = ({show, onClose}) => {
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content">
                <button onClick={onClose} className="close-button">❌</button>
                <iframe
                    src="https://www.google.com/chrome/"
                    title="Télécharger Google Chrome"
                    className="popup-iframe"
                />
            </div>
        </div>
    );
};

const InstructionsModal = ({show, onClose}) => {
    if (!show) return null;

    return (
        <div className="popup">
            <div className="popup-content popup-instruction">
                <button onClick={onClose} className="close-button close-button-instruction">❌</button>
                <h1>Pour ajouter cette application à votre écran d'accueil :</h1>
                <p id="majuscule">1. Cliquez sur l'icone de partage</p>
                <p id="minuscume">(icône entourée en rouge ci-dessous) :</p>
                <div className="img-chrome-safari">
                    <p>a. Si vous utilisez Safari :</p>
                    <img src={partage_safari} alt="Step 1"/>
                    <p>b. Si vous utilisez Google Chrome :</p>
                    <img src={partage_chrome} alt="Step 1"/>
                </div>
                <p>2. Faites défiler vers le bas et sélectionnez "Ajouter à l'écran d'accueil".</p>
                <img src={ajouteralecran} alt="Step 2"/>
                <p>3. Appuyez sur "Ajouter" dans le coin supérieur droit.</p>
                <img src={ajouter} alt="Step 3"/>
            </div>
        </div>
    );
};

const InstallPWA = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {

        const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        setIsInstalled(isStandalone);
        const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        setIsSafari(isSafariBrowser);


        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };


        const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);




        window.addEventListener("beforeinstallprompt", handler);
        window.addEventListener("appinstalled", () => {
            window.location.reload();
        });

        return () => {
            window.removeEventListener("beforeinstallprompt", handler);
            window.removeEventListener("appinstalled", () => {
            });
        };
    }, []);


    const onClick = (evt) => {
        evt.preventDefault();
        if (promptInstall) {
            promptInstall.prompt();
            promptInstall.userChoice.then((choiceResult) => {
                console.log(`User ${choiceResult.outcome === "accepted" ? "accepted" : "dismissed"} the install prompt`);
                setPromptInstall(null);
            });
        }
    };

    const redirectToChrome = () => {
        window.location = "intent://www.lesbonsplans.pro#Intent;package=com.android.chrome;scheme=https;end;";
        if (isMobile) {
            window.location = "https://www.google.com/chrome/";
        } else {
            setTimeout(function () {
                openModal();
            }, 2000);
        }
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const openInstructionsModal = () => setShowInstructionsModal(true);
    const closeInstructionsModal = () => setShowInstructionsModal(false);


    return (
        <div>
            {isInstalled ? null : (
                <div className="install-pwa">
                    {supportsPWA ? (
                        <button
                            className="install-btn"
                            id="setup_button"
                            aria-label="Installer l'application"
                            title="Installer l'application"
                            onClick={onClick}
                        >
                            <h1>Cliquez ici pour installer l'application sur l'écran d'accueil de votre appareil</h1>
                        </button>
                    ) : (!isSafari) && (
                        <div className="install">
                            <div className="install-text">
                                <div>
                                    <h1>Pour télécharger l'application, merci d'installer GOOGLE CHROME sur votre appareil
                                        (en cliquant sur le bouton ci-desus) ou de l'ouvrir si le vous l'avez déjà
                                        installé.
                                        :</h1>
                                    <button className="chrome-btn" onClick={redirectToChrome}>Installer / ouvrir Google
                                        Chrome
                                    </button>
                                    <Modal show={showModal} onClose={closeModal}/>
                                    <h2>Si vous avez déjà installé l'application, veuillez l'ouvrir depuis l'icone qui vient de s'installer sur votre l'écran d'accueil de votre appareil.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    )}
                    {isSafari && (
                        <div className="install">
                            <div className="install-text">
                                <div>
                                    <h1>Pour ajouter cette application à votre écran d'accueil :</h1>
                                    <button className="chrome-btn" onClick={openInstructionsModal}>Voir les
                                        instructions
                                    </button>
                                    <InstructionsModal show={showInstructionsModal} onClose={closeInstructionsModal}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InstallPWA;