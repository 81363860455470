import React from "react";
import NavbarAdmin from "../components/NavbarAdmin";
import HeaderAdmin from "../layouts/HeaderAdmin";

const HomeAdmin = () => {
  return (
    <div className="home-admin-container">
      <HeaderAdmin />
      <section className="admin-content-container">
        <NavbarAdmin />
        <h2>
          Bienvenue dans votre espace d'administration. Choisissez une catégorie
          pour commencer.
        </h2>
      </section>
    </div>
  );
};

export default HomeAdmin;
