import { GET_USER, GET_USER_BY_ID, SET_USER } from "../actions/user.action";

const initialState = {};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return action.payload;
    case GET_USER_BY_ID:
      return action.payload;
    case SET_USER:
      return [action.payload, ...state];
    default:
      return state;
  }
}
