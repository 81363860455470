import imageCompression from "browser-image-compression";

export const pictureCompressor = async (file) => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    };

    const compressFile = async (file) => {
        try {
            console.log(`Taille avant compression: ${file.size / 1024 / 1024} MB`);
            const compressedFile = await imageCompression(file, options);
            console.log(`Taille après compression: ${compressedFile.size / 1024 / 1024} MB`);

            let fileToUpload = compressedFile.size < file.size ? compressedFile : file;
            if (fileToUpload === file) {
                console.log("La version compressée est plus grande, utilisation de l'image originale.");
            }

            let formData = new FormData();
            formData.append("file", compressedFile);
            return formData;

        } catch (error) {
            console.error("Erreur lors de la compression de l'image :", error);
        }
    };

    if (Array.isArray(file)) {
        const formDataArray = [];
        for (let i = 0; i < file.length; i++) {
            const formData = await compressFile(file[i]);
            formDataArray.push(formData);
        }
        return formDataArray;
    } else {
        return await compressFile(file);
    }
};