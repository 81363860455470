import axios from "axios";
import { updateContentCity } from "./contentCity.action";
import {deletePictureGuidedTour} from "./pictureGuidedTour.action";

export const GET_MAGAZINE = "GET_MAGAZINE";
export const SET_MAGAZINE = "SET_MAGAZINE";
export const UPDATE_MAGAZINE = "UPDATE_MAGAZINE";
export const DELETE_MAGAZINE = "DELETE_MAGAZINE";

export const getMagazine = () => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro/magazine")
            .then((res) => {
                dispatch({ type: GET_MAGAZINE, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setMagazine = (magazine) => {
    return async (dispatch) => {
        return axios
            .post("https://api.lesbonsplans.pro/magazine", magazine)
            .then((res) => {
                dispatch({ type: SET_MAGAZINE, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const updateMagazine = (magazine) => {
    return async (dispatch) => {
        return axios
            .put(
                "https://api.lesbonsplans.pro/magazine/" + magazine._id,
                magazine
            )
            .then((res) => {
                dispatch({
                    type: UPDATE_MAGAZINE,
                    payload: { ...res.data, ...magazine },
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deleteMagazine = (magazine, contentCityId) => {
    return async (dispatch, getState) => {
        try {
            Object.keys(magazine.pictures).forEach((pictureId) => {
                dispatch(deletePictureGuidedTour(magazine.pictures[pictureId].id));
            });
            await axios.delete(`https://api.lesbonsplans.pro/magazine/${magazine._id}`);
            dispatch({
                type: DELETE_MAGAZINE,
                payload: magazine._id,
            });

            const { contentCityReducer } = getState();
            const updatedMagazines = contentCityReducer.magazines.filter(id => id !== magazine._id);

            dispatch(updateContentCity({
                _id: contentCityId,
                magazines: updatedMagazines,
            }));
        } catch (err) {
            console.log(err);
        }
    };
};