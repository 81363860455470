import React from "react";
import Header from "../layouts/Header";
import {useSelector} from "react-redux";

const MagCity = () => {
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    return (
        <div className="mag-city-container">
            <Header stringBtn={"Retourner aux magazines"} navPath={"magazines"}/>
            <h1>Magazine de la ville</h1>
            <div className="button-container">
                <button className="btn btn-link"
                        onClick={() => window.open(contentCurrentCity.cityMagLink, '_blank')}>Si la page ne s'affiche
                    pas
                    ou qu'elle s'affiche mal, cliquez ici.
                </button>
            </div>
            <section className="iframe-container">
                <iframe
                    title="magazine-ville"
                    src={contentCurrentCity.cityMagLink}
                    frameborder="0"
                ></iframe>
            </section>
        </div>
    );
};

export default MagCity;
