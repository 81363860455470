import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, generatesubDomain} from "../assets/Utils";
import {
    getCategories,
    setCategory,
    updateCategory,
    deleteCategory,
} from "../actions/category.action";
import DeleteConfirmationModal from "./AdminPopupSuppression";

const AdminCategoryManagement = () => {
    const dataCategoryManagement = useSelector((state) => state.categoryReducer);
    const dataPartner = useSelector((state) => state.partnerReducer);
    const [newCategory, setNewCategory] = useState("");
    const [modifiedCategory, setModifiedCategory] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const copyDataCategoryManagement = [...dataCategoryManagement];
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [partnersWithSingleCategory, setPartnersWithSingleCategory] = useState([]);
    const currentItems = copyDataCategoryManagement
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(indexOfFirstItem, indexOfLastItem);
    const dispatch = useDispatch();

    const handleCreateCategory = () => {
        const categoryExists = dataCategoryManagement.some(
            (category) => category.name.toLowerCase() === newCategory.toLowerCase()
        );

        if (categoryExists) {
            window.alert("Cette catégorie existe déjà.");
            return;
        }

        const newCategoryObject = {
            name: newCategory,
            subDomain: generatesubDomain(newCategory, dataCategoryManagement),
            isActive: true,
        };
        dispatch(setCategory(newCategoryObject));
        setNewCategory("");
    };

    const handleRefreshCategories = () => {
        dispatch(getCategories());
        window.alert(
            "Tableau des catégories actualisé.\nToute les catégories sont à présent modifiables et activables."
        );
    };

    const handleActivateCategory = (e, category) => {
        const updatedCategory = {...category, isActive: !category.isActive};
        dispatch(updateCategory(updatedCategory));
    };

    const handleUpdateCategory = (origineCategory) => {
        const categoryExists = dataCategoryManagement.some(
            (category) => category.name.toLowerCase() === modifiedCategory.name.toLowerCase() && category._id !== origineCategory._id
        );

        if (categoryExists) {
            window.alert("Ce nom de catégorie existe déjà.");
            return;
        }

        const partners = partnerWithTheCategory(origineCategory);
        dispatch(updateCategory(modifiedCategory, partners, origineCategory));
        setModifiedCategory({});
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const partnerWithTheCategory = (category) => {
        try {
            return dataPartner
                .filter((partner) => partner.category.includes(category.name))
                .map((partner) => partner);
        } catch (error) {
            console.error("Erreur lors de la récupération des partenaires :", error);
            return [];
        }
    };

    const handleDeleteClick = async (category) => {
        setCategoryToDelete(category);
        try {
            const filteredPartners = dataPartner
                .filter(
                    (partner) =>
                        partner.category.length === 1 &&
                        partner.category[0] === category.name
                )
                .map((partner) => partner);
            setPartnersWithSingleCategory(filteredPartners);
        } catch (error) {
            console.error("Erreur lors de la récupération des partenaires :", error);
        }
        setShowDeleteModal(true);

        const row = document.querySelector(`tr[data-category-id="${category._id}"]`);
        if (row) {
            row.classList.add('deleted');
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);

        const row = document.querySelector(`tr[data-category-id="${categoryToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
        setCategoryToDelete(null);
    };

    const handleConfirmDelete = () => {
        const partners = partnerWithTheCategory(categoryToDelete);
        dispatch(deleteCategory(partners, categoryToDelete));
        setShowDeleteModal(false);

        const row = document.querySelector(`tr[data-category-id="${categoryToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
        setCategoryToDelete(null);
    };

    return (
        <div>
            <section className="category-management">
                <h2>Gestion des catégories</h2>
                <div className="new-category">
                    <input
                        type="text"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <button
                        onClick={() => handleCreateCategory()}
                        disabled={newCategory.length < 1}
                        id={newCategory.length < 1 ? "disabledCreateCat" : ""}
                    >
                        Créer la catégorie
                    </button>
                </div>
                <button className="btn-refresh" onClick={() => handleRefreshCategories()}>
                    Actualiser <i className="bx bx-refresh"></i>
                </button>
                <table>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Actif</th>
                        <th className="contain-btn">Activation</th>
                        <th className="contain-btn">Modifier</th>
                        <th className="contain-btn">Suppression</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isEmpty(currentItems) &&
                        currentItems.map((category) => (
                            <tr key={category._id} data-category-id={category._id}>
                                <td>
                                    {category._id ? (
                                        modifiedCategory._id === category._id ? (
                                            <input
                                                type="text"
                                                defaultValue={category.name}
                                                onChange={(e) =>
                                                    setModifiedCategory({
                                                        ...modifiedCategory,
                                                        name: e.target.value,
                                                        subDomain: generatesubDomain(e.target.value,dataCategoryManagement, category.subDomain),
                                                    })
                                                }
                                            />
                                        ) : (
                                            category.name
                                        )
                                    ) : (
                                        category.name
                                    )}
                                </td>
                                <td>{category.isActive ? "Oui" : "Non"}</td>
                                <td className="contain-btn">
                                    {category._id ? (
                                        <button onClick={(e) => handleActivateCategory(e, category)}>
                                            {category.isActive ? "Désactiver" : "Activer"}
                                        </button>
                                    ) : (
                                        <button disabled>Actualisez pour Activer/Désactiver</button>
                                    )}
                                </td>
                                <td className="contain-btn">
                                    {category._id ? (
                                        modifiedCategory._id === category._id ? (
                                            <>
                                                <button onClick={() => setModifiedCategory({})}>
                                                    Annuler
                                                </button>
                                                <button onClick={() => handleUpdateCategory(category)}>
                                                    Valider les modifications
                                                </button>
                                            </>
                                        ) : (
                                            <button onClick={() => setModifiedCategory(category)}>
                                                Modifier
                                            </button>
                                        )
                                    ) : (
                                        <button disabled>Actualisez pour modifier</button>
                                    )}
                                </td>
                                <td  className="contain-btn">
                                    {category._id ? (
                                    <button onClick={() => handleDeleteClick(category)}>
                                        Supprimer
                                    </button>
                                    ) : (
                                        <button disabled>Actualisez pour supprimer</button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <button onClick={handlePreviousPage} hidden={currentPage === 1}>
                        Précédent
                    </button>
                    <button
                        onClick={handleNextPage}
                        hidden={indexOfLastItem >= dataCategoryManagement.length}
                    >
                        Suivant
                    </button>
                </div>
            </section>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                partnersWithSingleCategory={partnersWithSingleCategory}
                who="cette catégorie"
            />
        </div>
    );
};

export default AdminCategoryManagement;