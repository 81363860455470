import React from "react";
import { NavLink } from "react-router-dom";

const BackButton = ({ navPath, stringBtn }) => {
  return (
    <div className="back-button">
      <NavLink to={navPath ? `/${navPath}` : "/accueil"}>
        <button>{stringBtn}</button>
      </NavLink>
    </div>
  );
};

export default BackButton;
