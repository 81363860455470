import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { NavLink } from "react-router-dom";
import CityListEntry from "../components/CityListEntry";
import LogoLbp from "../components/LogoLbp";
import InstallPWA from "../components/InstallPwa";

const HomeRedirect = () => {
  const [nearestCity, setNearestCity] = useState({});
  const dataCity = useSelector((state) => state.cityReducer);
  const [currentLocation, setCurrentLocation] = useState({});

  const collectDataLocation = (pos) => {
    const crd = pos.coords;
    const data = {
      latitude: crd.latitude,
      longitude: crd.longitude,
    };
    setCurrentLocation(data);
    findNearestCity(data);
  };

  const findNearestCity = async (currentLocation) => {
    let nearestCityPos = [0, 0];
    if (isEmpty(dataCity) || !Array.isArray(dataCity)) return;
    for (const city of dataCity) {
      let coords = [];
      if (city.isActive === true) {
        coords = await city.coordinates;
      } else {
        coords = [0, 0];
      }
      let deltaLatNear = nearestCityPos[0] - currentLocation.latitude;
      let deltaLngNear = nearestCityPos[1] - currentLocation.longitude;
      let deltaLat = coords[0] - currentLocation.latitude;
      let deltaLng = coords[1] - currentLocation.longitude;
      if (
          deltaLat * deltaLat + deltaLng * deltaLng <
          deltaLatNear * deltaLatNear + deltaLngNear * deltaLngNear
      ) {
        nearestCityPos = coords;
        setNearestCity(city);
      }
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(collectDataLocation);
  }, [dataCity]);

  return (
      <div className="home-redirect-container">
        <InstallPWA />
        <section className="logo-container">
          <LogoLbp />
        </section>
        <section className="city-selector">
          <h1>Choisissez votre ville</h1>
          <div className="city-display">
            {Array.isArray(dataCity) &&
                !isEmpty(dataCity) &&
                dataCity
                    .filter((city) => city.isActive === true)
                    .filter((city) => city.name === nearestCity.name)
                    .map((city) => (
                        <CityListEntry key={city._id ? city._id : 1} city={city} currentLocation={currentLocation}/>
                    ))}
            <div className="other-cities">
              <div className="button-cities-container">
                <NavLink to="/selection-ville">
                  <button>
                    <i className="bx bxs-buildings"></i>
                    <span>Choisir une autre ville</span>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
};

export default HomeRedirect;
