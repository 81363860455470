import axios from "axios";
import { deleteElement } from "./partnerUploads.action";
import { updateContentCity } from "./contentCity.action"; // Importez l'action updateContentCity

export const GET_MESSAGE = "GET_MESSAGE";
export const SET_MESSAGE = "SET_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const getMessage = () => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro" + "/message")
            .then((res) => {
                dispatch({ type: GET_MESSAGE, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setMessage = (message, contentCityId) => {
    return async (dispatch) => {
        return axios
            .post("https://api.lesbonsplans.pro" + "/message", message)
            .then(async (res) => {
                const newMessage = res.data;
                dispatch({ type: SET_MESSAGE, payload: newMessage });

                // Mettez à jour les contentCity des villes sélectionnées
                for (const contentCitiesId of contentCityId) {
                    const contentCity = await axios.get(`https://api.lesbonsplans.pro/contentCity/${contentCitiesId}`);
                    const updatedContentCity = {
                        ...contentCity.data,
                        messages: [...contentCity.data.messages, newMessage._id]
                    };
                    dispatch(updateContentCity(updatedContentCity));
                }
            })
            .catch((err) => console.log(err));
    };
};

export const updateMessage = (message, contentCityId) => {
    return async (dispatch) => {
        return axios
            .put("https://api.lesbonsplans.pro" + "/message/" + message._id, message)
            .then(async (res) => {
                dispatch({
                    type: UPDATE_MESSAGE,
                    payload: {...res.data, ...message},
                });

                // Mettez à jour les contentCity des villes sélectionnées
                for (const contentCitiesId of contentCityId) {
                    const contentCity = await axios.get(`https://api.lesbonsplans.pro/contentCity/${contentCitiesId}`);
                    if (!contentCity.data.messages.includes(message._id)) {
                        const updatedContentCity = {
                            ...contentCity.data,
                            messages: [...contentCity.data.messages, message._id]
                        };
                        dispatch(updateContentCity(updatedContentCity));
                    }
                }

                // Supprimez le message des contentCity des villes non sélectionnées
                const allContentCities = await axios.get("https://api.lesbonsplans.pro/contentCity");
                for (const contentCity of allContentCities.data) {
                    if (!contentCityId.includes(contentCity._id) && contentCity.messages.includes(message._id)) {
                        const updatedContentCity = {
                            ...contentCity,
                            messages: contentCity.messages.filter(msgId => msgId !== message._id)
                        };
                        dispatch(updateContentCity(updatedContentCity));
                    }
                }
            })
            .catch((err) => console.log(err));
    };
};

export const deleteMessage = (message, contentCityId) => {
    return async (dispatch) => {
        dispatch(await deleteElement(message.logo));
        for (const upload of message.contentUploads) {
            dispatch(await deleteElement(upload.id));
        }

        // Supprimez le message des contentCity associés
        for (const contentCitiesId of contentCityId) {
            const contentCity = await axios.get(`https://api.lesbonsplans.pro/contentCity/${contentCitiesId}`);
            const updatedContentCity = {
                ...contentCity.data,
                messages: contentCity.data.messages.filter(msgId => msgId !== message._id)
            };
            dispatch(updateContentCity(updatedContentCity));
        }

        return axios
            .delete("https://api.lesbonsplans.pro" + "/message/" + message._id)
            .then((res) => {
                dispatch({
                    type: DELETE_MESSAGE,
                    payload: message._id,
                });
            })
            .catch((err) => console.log(err));
    };
};