import {
    GET_MAGAZINE,
    SET_MAGAZINE,
    UPDATE_MAGAZINE,
    DELETE_MAGAZINE,
} from "../actions/magazine.action";

const initialState = [];

export default function magazineReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MAGAZINE:
            return action.payload;
        case SET_MAGAZINE:
            return action.payload;
        case UPDATE_MAGAZINE:
            return state.map((magazine) =>
                magazine._id === action.payload._id ? action.payload : magazine
            );
        case DELETE_MAGAZINE:
            return state.filter((magazine) => magazine._id !== action.payload);
        default:
            return state;
    }
}
