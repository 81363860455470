import React from "react";
import { useSelector } from "react-redux";
import Header from "../layouts/Header";

const LiveCam = () => {
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  return (
      <div className="live-cam-container">
          <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"}/>
          <h1>La ville en direct</h1>
          <div className="button-container">
              <button className="btn btn-link"
                      onClick={() => window.open(contentCurrentCity.liveCamLink, '_blank')}>Si la page ne s'affiche pas
                  ou qu'elle s'affiche mal, cliquez ici.
              </button>
          </div>
          <section className="iframe-container">
              <iframe
                  title="webcam"
                  src={contentCurrentCity.liveCamLink}
                  frameborder="0"
              ></iframe>
          </section>
      </div>
  );
};

export default LiveCam;
