import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const CountrySelect = ({ onChange, value }) => {
  const dataCity = useSelector((state) => state.cityReducer);
  const [optionsCountry, setOptionsCountry] = useState([]);

  useEffect(() => {
    if (Array.isArray(dataCity)) {
      const activeCountries = [
        ...new Set(dataCity.filter((city) => city.isActive).map((city) => city.country)),
      ].map((country) => ({value: country, label: country}));
      setOptionsCountry(activeCountries);
    }
  }, [dataCity]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "1rem",
      margin: "20px 0",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
        cursor: "text",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
  };

  return (
      <Select
          options={optionsCountry}
          onChange={onChange}
          value={value}
          styles={customStyles}
          placeholder="Sélectionnez un pays"
          isSearchable
          isClearable
      />
  );
};

export default CountrySelect;