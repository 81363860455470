import {
  DELETE_ELEMENT,
  REORDER_ELEMENTS,
  RESET,
  SET_LOGO_PARTNER,
  SET_PICTURE_PARTNER,
  SET_VIDEO_PARTNER,
  INITIALIZE_UPLOADS,
} from "../actions/partnerUploads.action";

const initialState = { logo: "", uploads: [] };

export default function partnerUploadsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PICTURE_PARTNER:
      return {
        ...state,
        uploads: [
          ...state.uploads,
          { id: action.payload.fileId, type: "picture" },
        ],
      };
    case SET_LOGO_PARTNER:
      return { ...state, logo: action.payload.fileId };
    case SET_VIDEO_PARTNER:
      return {
        ...state,
        uploads: [
          ...state.uploads,
          { id: action.payload.fileId, type: "video" },
        ],
      };
    case DELETE_ELEMENT:
      return {
        ...state,
        logo: state.logo === action.payload ? "" : state.logo,
        uploads: state.uploads.filter((upload) => upload.id !== action.payload),
      };
    case REORDER_ELEMENTS:
      return {
        ...state,
        uploads: action.payload,
      };
    case INITIALIZE_UPLOADS:
      return {
        ...state,
        uploads: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}