import {
  DELETE_ELEMENT_CITY,
  RESET_REDUCER_CITY_UPLOADS,
  SET_BACKGROUND_CITY,
  SET_LOGO_CITY,
} from "../actions/cityUploads.action";

const initialState = {};

export default function cityUploadsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGO_CITY:
      return { ...state, logo: action.payload };
    case SET_BACKGROUND_CITY:
      return { ...state, background: action.payload };
    case DELETE_ELEMENT_CITY:
      return {
        ...state,
        logo: state.logo === action.payload ? "" : state.logo,
        background: state.background === action.payload ? "" : state.background,
      };
    case RESET_REDUCER_CITY_UPLOADS:
      return initialState;
    default:
      return state;
  }
}
