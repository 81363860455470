import axios from "axios";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const TRY_CONNECTION = "TRY_CONNECTION";

export const login = (user) => ({
  type: LOGIN,
  payload: user,
});

export const logout = () => ({
  type: LOGOUT,
});

export const handleUserConnection = (userName, password) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/admin/login", {
        userName: userName,
        password: password,
      })
      .then((res) => {
        dispatch({ type: TRY_CONNECTION, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
