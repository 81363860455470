import React from "react";
import {useSelector} from "react-redux";
import Header from "../layouts/Header";

const NewsCity = () => {
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    return (
        <div className="news-city-container">
            <Header stringBtn={"Retourner découvrir"} navPath={"decouverte"}/>
            <h1>Actualités</h1>
            <div className="button-container">
                <button className="btn btn-link"
                        onClick={() => window.open(contentCurrentCity.cityNewsLink, '_blank')}>Si la page ne s'affiche
                    pas ou qu'elle s'affiche mal, cliquez ici.
                </button>
            </div>
            <section className="iframe-container">
                <iframe
                    title="actualites"
                    src={contentCurrentCity.cityNewsLink}
                    frameborder="0"
                ></iframe>
            </section>
        </div>
    );
};

export default NewsCity;
