import axios from "axios";
import {updatePartner} from "./partner.action";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const CURRENT_CATEGORY = "CURRENT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const REMOVE_CATEGORY_FROM_USERS = "REMOVE_CATEGORY_FROM_USERS";

export const getCategories = () => {
  return async (dispatch) => {
    return axios
      .get("https://api.lesbonsplans.pro" + "/category")
      .then((res) => {
        dispatch({ type: GET_CATEGORIES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const setCategory = (category) => {
  return async (dispatch) => {
    return axios
      .post("https://api.lesbonsplans.pro" + "/category", category)
      .then((res) => {
        dispatch({ type: SET_CATEGORY, payload: category });
      })
      .catch((err) => console.log(err));
  };
};

export const updateCategory = (category, partnerWithTheCategory,origineCategory) => {
    return async (dispatch) => {
        try {
            const res = await axios.put(
                "https://api.lesbonsplans.pro" + "/category/" + category._id,
                category
            );
            dispatch({
                type: UPDATE_CATEGORY,
                payload: { ...res.data, ...category },
            });
            for (const partner of partnerWithTheCategory) {
                const updatedCategories = partner.category.map(cat =>
                    cat === origineCategory.name ? category.name : cat
                );
                await dispatch(updatePartner({ ...partner, category: updatedCategories }));
            }
        } catch (err) {
            console.error("Erreur lors de la mise à jour de la catégorie :", err);
        }
    };
};

export const setCurrentCategory = (category) => {
  return async (dispatch) => {
    dispatch({ type: CURRENT_CATEGORY, payload: category });
  };
};

export const deleteCategory = (partnersWithTheCategory, category) => {
    return async (dispatch) => {
        try {
            await axios.delete("https://api.lesbonsplans.pro/category/" + category._id);
            dispatch({
                type: DELETE_CATEGORY,
                payload: category._id,
            });

            for (const partner of partnersWithTheCategory) {
                await dispatch(removeCategoryFromUsers(partner, category.name));
            }
        } catch (err) {
            console.error("Erreur lors de la suppression de la catégorie :", err);
        }
    };
};

export const removeCategoryFromUsers = (partner, categoryToRemove) => {
    return async (dispatch) => {
        try {
            await axios.patch("https://api.lesbonsplans.pro/partner/removeCategory", { partner, categoryToRemove });
            dispatch({
                type: REMOVE_CATEGORY_FROM_USERS,
                payload: { partner, categoryToRemove },
            });
        } catch (err) {
            console.error("Erreur lors de la suppression de la catégorie des utilisateurs :", err);
        }
    };
};
