import React from "react";
import { isEmpty } from "../assets/Utils";

const DeleteConfirmationModal = ({ show, onClose, onConfirm, partnersWithSingleCategory, who }) => {
    if (!show) return null;

    const handleConfirmClick = () => {
        if (who === "cette ville") {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette ville ? Cette action est irréversible.");
            if (!isConfirmed) {
                return;
            }
        }
        onConfirm();
    };

    return (
        <div className="popup-Admin-Suppression">
            <div className="popup-content">
                <button onClick={onClose} className="close-button">❌</button>
                <h2>Confirmer la suppression</h2>
                {isEmpty(partnersWithSingleCategory) ? (
                    <>
                        <p>Êtes-vous sûr de vouloir supprimer {who} ?</p>
                        <button onClick={handleConfirmClick} className="confirm-button">Confirmer</button>
                    </>
                ) : (
                    <>
                        <p>Vous ne pouvez pas supprimer cette catégorie car des partenaires ne possèdent que celle-ci :</p>
                        <div>
                            <h3>Partenaires avec cette seule catégorie :</h3>
                            <ul>
                                {partnersWithSingleCategory.map((partner, index) => (
                                    <li key={index}>{partner.name}</li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
                <button onClick={onClose} className="cancel-button">Annuler</button>
            </div>
        </div>
    );
};

export default DeleteConfirmationModal;