import axios from "axios";

export const SET_AUDIO_GUIDED_TOUR = "SET_AUDIO_GUIDED_TOUR";
export const DELETE_AUDIO_GUIDED_TOUR = "DELETE_AUDIO_GUIDED_TOUR";
export const RESET_AUDIO_GUIDED_TOUR = "RESET_AUDIO_GUIDED_TOUR";

export const setAudioGuidedTour = (audioGuidedTour) => {
  return async (dispatch) => {
    return axios
      .post(
        "https://api.lesbonsplans.pro" + "/upload/upload",
        audioGuidedTour,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        dispatch({ type: SET_AUDIO_GUIDED_TOUR, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteAudioGuidedTour = (audioGuidedTour) => {
  return async (dispatch) => {
    return axios
      .delete(
        "https://api.lesbonsplans.pro" + "/upload/upload/" + audioGuidedTour
      )
      .then((res) => {
        dispatch({ type: DELETE_AUDIO_GUIDED_TOUR, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const resetAudioGuidedTour = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_AUDIO_GUIDED_TOUR });
  };
};
