import React from "react";
import LogoLbp from "../components/LogoLbp";
import NavbarAdmin from "../components/NavbarAdmin";

const HeaderAdmin = () => {
  return (
    <section className="header-admin-container">
      <h1>Administration</h1>
      <LogoLbp />
    </section>
  );
};

export default HeaderAdmin;
