import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDispatch } from "react-redux";
import { CSS } from "@dnd-kit/utilities";
import { reorderElements } from "../actions/partnerUploads.action";

const SortableItem = ({ upload, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: upload.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
      <div
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={style}
          className={
            upload.type === "picture"
                ? "picture-new-partner-container"
                : "video-new-partner-container"
          }
      >
        {upload.type === "picture" ? (
            <>
              <img
                  src={`${"https://api.lesbonsplans.pro"}/upload/partner/image/${
                      upload.id
                  }`}
                  alt="Photo ajoutée"
                  width={150}
              />
              <i
                  className="bx bx-x-circle"
                  title="Supprimer la photo"
                  onClick={() => onDelete(upload.id)}
              ></i>
            </>
        ) : (
            <>
              <video controls width={300}>
                <source
                    src={`${"https://api.lesbonsplans.pro"}/upload/partner/video/${
                        upload.id
                    }`}
                    type="video/mp4"
                />
              </video>
              <i
                  className="bx bx-x-circle"
                  title="Supprimer la vidéo"
                  onClick={() => onDelete(upload.id)}
              ></i>
            </>
        )}
      </div>
  );
};

const AdminSortingPartnerUploadsUpdate = ({ onDeleteIdsChange, uploads }) => {
  const dispatch = useDispatch();
  const [deletedIds, setDeletedIds] = useState([]);

  const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 10,
        },
      })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = uploads.findIndex((upload) => upload.id === active.id);
      const newIndex = uploads.findIndex((upload) => upload.id === over.id);

      const newUploads = arrayMove(uploads, oldIndex, newIndex);
      dispatch(reorderElements(newUploads));
    }
  };

  const handleDelete = async (id) => {
    setDeletedIds((prevIds) => [...prevIds, id]);
    onDeleteIdsChange([...deletedIds, id]);
  };

  return (
      <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
      >
        <fieldset className="uploaded-files-container">
          <legend>Fichiers ajoutés (Photos + Vidéos)</legend>
          <SortableContext
              items={uploads.map((upload) => upload.id)}
              strategy={verticalListSortingStrategy}
          >
            <div className="uploaded-files">
              {uploads.map((upload) => (
                  <SortableItem
                      key={upload.id}
                      upload={upload}
                      onDelete={handleDelete}
                  />
              ))}
            </div>
          </SortableContext>
        </fieldset>
      </DndContext>
  );
};

export default AdminSortingPartnerUploadsUpdate;