import React from "react";
import Logo from "../components/LogoCity";
import BackButton from "../components/BackButton";

const Header = ({ navPath, stringBtn }) => {
  return (
    <header>
      <BackButton
        navPath={navPath ? navPath : ""}
        stringBtn={stringBtn ? stringBtn : "Retourner à l'accueil"}
      />
      <section className="logo-container">
        <Logo />
      </section>
    </header>
  );
};

export default Header;
