import React, { useState } from "react";
import { handleUserConnection, login } from "../actions/auth.action";
import store from "..";
import { getUser, getUserById } from "../actions/user.action";
import { useNavigate } from "react-router";
import LogoLbp from "../components/LogoLbp";

const Connexion = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await store.dispatch(handleUserConnection(userName, password));
    if (store.getState().authReducer.auth.response) {
      const userId = store.getState().authReducer.auth.id;
      await store.dispatch(getUserById(userId));
      const user = store.getState().userReducer;
      store.dispatch(login(user));
      store.dispatch(getUser());
      navigate("/administration/accueil");
      return;
    }
    alert(store.getState().authReducer.auth.message);
  };

  return (
    <div className="connection-container">
      <section className="logo-container">
        <LogoLbp />
      </section>
      <section className="content-container">
        <h1>Connexion</h1>
        <div className="connect-form-container">
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <input
              type="text"
              placeholder="Nom d'utilisateur"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input type="submit" value="Se connecter" />
          </form>
        </div>
      </section>
    </div>
  );
};

export default Connexion;
