import React, {useEffect, useState} from 'react';
import {isEmpty} from "../assets/Utils";
import AdminSortingPartnerUploadsUpdate from "../components/AdminSortingPartnerUploadsUpdate";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteElement,
    resetReducer,
    setPicturePartner,
    initializeUploads
} from "../actions/partnerUploads.action";
import {deleteMagazine, updateMagazine} from "../actions/magazine.action";
import ImageGallery from "../components/ImageGallery";
import {pictureCompressor} from "../assets/utils/pictureCompressor";

const AdminUpdateMagazine = ({contentCityId, magazine, closePopup}) => {
    const contentNewMagazine = useSelector(
        (state) => state.partnerUploadsReducer
    );
    const [deletedUploadIds, setDeletedUploadIds] = useState([]);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [uploadsUpdateMagazine, setUploadsUpdateMagazine] = useState(
        magazine.pictures
    );
    const [isFormValid, setIsFormValid] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initializeUploads(magazine.pictures));
    }, [dispatch, magazine.pictures]);

    useEffect(() => {
        if (!isEmpty(contentNewMagazine.uploads)) {
            setUploadsUpdateMagazine(contentNewMagazine.uploads);
        }
    }, [contentNewMagazine.uploads]);

    const handlePictureUpload = async (e) => {
        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            try {
                let formDataPicture = await pictureCompressor(files[i]);
                dispatch(await setPicturePartner(formDataPicture));
            } catch (error) {
                console.error("Erreur lors de la compression de l'image :", error);
            }
        }
        handleIsFormValid();
    };


    const handleDeletedIdsChange = (ids) => {
        setDeletedUploadIds(ids);
        const updatedUploads = uploadsUpdateMagazine.filter(upload => !ids.includes(upload.id));
        setUploadsUpdateMagazine(updatedUploads);
        handleIsFormValid();
    };

    const resetUpdatePopup = async () => {
        const newPicture = newPictureUpload();
        for (const upload of newPicture) {
            dispatch(await deleteElement(upload));
        }
        dispatch(resetReducer());
        closePopup({});
    };

    const newPictureUpload = () => {
        const newUploads = contentNewMagazine.uploads.filter(upload =>
            !magazine.pictures.some(partnerUpload => partnerUpload.id === upload.id)
        );
        return newUploads.map(upload => upload.id);
    };

    const handleIsFormValid = () => {
        const form = document.getElementById("form-new-magazine");
        if (form.checkValidity()) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid) {
            return;
        }
        const form = e.target;
        let updatesMagazines = {};
        let isChanged = false;

        if (form["name-magazine"].value !== magazine.name) {
            updatesMagazines.name = form["name-magazine"].value;
            isChanged = true;
        }

        if (form["number-magazine"].value !== magazine.numberMag) {
            updatesMagazines.numberMag = form["number-magazine"].value;
            isChanged = true;
        }

        if (uploadsUpdateMagazine !== magazine.pictures) {
            for (const upload1 of magazine.pictures
                .filter(upload => !uploadsUpdateMagazine.some(updatedUpload => updatedUpload.id === upload.id))) {
                dispatch(await deleteElement(upload1.id));
            }

            updatesMagazines = {
                ...updatesMagazines,
                pictures: uploadsUpdateMagazine
            };

            isChanged = true;
        }

        if (isChanged) {
            updatesMagazines = {...updatesMagazines, _id: magazine._id};
            dispatch(updateMagazine(updatesMagazines));
        }
        dispatch(resetReducer());
        closePopup({});
    }
    const handleConfirmClick = () => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce magazine ?");
        if (!isConfirmed) {
            return;
        }
        dispatch(deleteMagazine(magazine, contentCityId));
        closePopup();
    };

    return (
        <section className="popup popup-magazine">
            <div className="popup-content-magazine-container">
                <div className="popup-magazine-header">
                    <h2>Créer un magazine</h2>
                    {galleryOpen && (
                        <ImageGallery
                            images={uploadsUpdateMagazine.map(upload => ({url: `${"https://api.lesbonsplans.pro"}/upload/partner/image/${upload.id}`}))}
                            onClose={() => setGalleryOpen(false)}
                        />
                    )}
                    <div className="cancel-button" onClick={resetUpdatePopup}>
                        <p>Annuler</p>
                        <i className="bx bx-x-circle"></i>
                    </div>
                </div>
                <form id="form-new-magazine" autoComplete="off" onSubmit={handleSubmit}>
                    <div className="label-info-magazine-container">
                        <label htmlFor="name-magazine">Nom du magazine</label>
                        <input
                            type="text"
                            id="name-magazine"
                            name="name-magazine"
                            defaultValue={magazine.name}
                            required
                            onChange={handleIsFormValid}
                        />
                        <label htmlFor="number-magazine">Numéro du magazine</label>
                        <input
                            type="text"
                            id="number-magazine"
                            name="number-magazine"
                            defaultValue={magazine.numberMag}
                            required
                            onChange={handleIsFormValid}
                        />
                    </div>
                    <section className="magazine-image">
                        <h3>Contenu</h3>
                        <div className="magazine-content-container">
                            <label htmlFor="picture-content">Ajouter une photo</label>
                            <input
                                type="file"
                                id="picture-content"
                                onChange={handlePictureUpload}
                                hidden
                                multiple
                            />
                            <button type="button" className="button-pictures" onClick={() => setGalleryOpen(true)}>Voir
                                les images
                            </button>

                        </div>
                    </section>
                    {!isEmpty(uploadsUpdateMagazine) && (
                        <AdminSortingPartnerUploadsUpdate
                            onDeleteIdsChange={handleDeletedIdsChange}
                            uploads={uploadsUpdateMagazine}
                        />
                    )}
                    <div className="magazine-footer">
                        <input
                            id={isFormValid ? "valid" : "invalid"}
                            type="submit"
                            value="Modifier le magazine"
                        />
                        <button type="button" className="delete-button" onClick={handleConfirmClick}>Supprimer</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AdminUpdateMagazine;