import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setCurrentCity } from "../actions/city.action";
import { getContentCityById } from "../actions/contentCity.action";
import { calculateDistance } from "../assets/utils/distance";

const CityListEntry = ({ city, currentLocation }) => {
    const dispatch = useDispatch();
    const [distance, setDistance] = useState(null);

    const onClickRedirect = (currentCity) => {
        dispatch(setCurrentCity(currentCity));
        dispatch(getContentCityById(currentCity.contentCity));
    };

    useEffect(() => {
        const fetchCoordinatesAndCalculateDistance = async () => {
            let cityCoordinates = await city.coordinates;
            if (currentLocation && city.name) {
                const dist = calculateDistance(
                    currentLocation.latitude,
                    currentLocation.longitude,
                    cityCoordinates[0],
                    cityCoordinates[1]
                );
                setDistance(dist);
            }
        };

        fetchCoordinatesAndCalculateDistance();
    }, [currentLocation, city]);

    return (
        <div className="city-list-entry-container">
            <NavLink
                to="/accueil"
                key={city._id}
                state={city}
                onClick={() => onClickRedirect(city)}
            >
                <img
                    src={
                        "https://api.lesbonsplans.pro" +
                        "/upload/city/image/" +
                        city.picture
                    }
                    alt={"Photo ville " + city.name}
                />
                <div className="nameDistance">
                    <span className="name"> {city.name}</span>
                    <div className="distance">
                        <p>{distance ? `${distance} km` : "N/A"}</p>
                    </div>
                </div>

            </NavLink>
        </div>
    );
};

export default CityListEntry;