import React from "react";
import {useSelector} from "react-redux";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Magazines from "./pages/Magazines";
import Calendar from "./pages/Calendar";
import SocialMedia from "./pages/SocialMedia";
import PartnersList from "./pages/PartnersList";
import Partner from "./pages/Partner";
import HomeRedirect from "./pages/HomeRedirect";
import CitiesList from "./pages/CitiesList";
import Connexion from "./pages/Connexion";
import HomeAdmin from "./pages/HomeAdmin";
import AdminUsers from "./pages/AdminUsers";
import AdminCities from "./pages/AdminCities";
import AdminPartners from "./pages/AdminPartners";
import AdminMessages from "./pages/AdminMessages";
import CategoriesList from "./pages/CategoriesList";
import PracticalGuideRedirect from "./pages/PracticalGuideRedirect";
import StreetsIndex from "./pages/StreetsIndex";
import PracticalInfos from "./pages/PracticalInfos";
import Weather from "./pages/Weather";
import AdminContent from "./pages/AdminContent";
import LiveCam from "./pages/LiveCam";
import NewsCity from "./pages/NewsCity";
import MagCity from "./pages/MagCity";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PartnerLink from "./pages/PartnerLink";
import WidgetPagesJaunes from "./pages/WidgetPagesJaunes";
import GuidedTourList from "./pages/GuidedTourList";
import GuidedTour from "./pages/GuidedTour";
import MagazineRedirect from "./pages/MagazineRedirect";
import GuidePrestige from "./pages/GuidePrestige";
import MessagesList from "./pages/MessagesList";
import Message from "./pages/Message";

const App = () => {
    const PrivateRoute = ({children}) => {
        const isAuthenticated = useSelector(
            (state) => state.authReducer.isAuthenticated
        );
        if (!isAuthenticated) {
            window.location.href = "/administration/connexion";
            return;
        }
        return children;
    };

    const CityRoute = ({children}) => {
        const currentCity = useSelector((state) => state.cityReducer);
        if (Array.isArray(currentCity)) {
            window.location.href = "/ma-ville";
            return;
        }
        return children;
    };

    return (
        <BrowserRouter>
            <Routes>
                {/* Pages de redirections */}
                <Route path="/" element={<HomeRedirect/>}/>
                <Route path="*" element={<HomeRedirect/>}/>
                <Route path="/ma-ville" element={<HomeRedirect/>}/>
                <Route path="/selection-ville" element={<CitiesList/>}/>
                <Route path="/page-partenaire" element={<PartnerLink/>}/>
                <Route path="/pages-jaunes" element={<WidgetPagesJaunes/>}/>

                {/* Pages d'administration */}
                <Route path="/administration/connexion" element={<Connexion/>}/>
                <Route
                    path="/administration/accueil"
                    element={
                        <PrivateRoute>
                            <HomeAdmin/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/administration/utilisateurs"
                    element={
                        <PrivateRoute>
                            <AdminUsers/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/administration/gestion-villes"
                    element={
                        <PrivateRoute>
                            <AdminCities/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/administration/contenu-ville"
                    element={
                        <PrivateRoute>
                            <AdminContent/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/administration/partenaires"
                    element={
                        <PrivateRoute>
                            <AdminPartners/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/administration/notifications"
                    element={
                        <PrivateRoute>
                            <AdminMessages/>
                        </PrivateRoute>
                    }
                />

                {/* Pages utilisateur */}
                <Route
                    path="/accueil"
                    element={
                        <CityRoute>
                            <Home/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/magazines"
                    element={
                        <CityRoute>
                            <MagazineRedirect/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/nos-magazines"
                    element={
                        <CityRoute>
                            <Magazines/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/agenda"
                    element={
                        <CityRoute>
                            <Calendar/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/reseaux-sociaux"
                    element={
                        <CityRoute>
                            <SocialMedia/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/nos-messages"
                    element={
                        <CityRoute>
                            <MessagesList/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/message"
                    element={
                        <CityRoute>
                            <Message/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/categories"
                    element={
                        <CityRoute>
                            <CategoriesList/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/nos-partenaires/:citysubDomain/:categorysubDomain"
                    element={
                        <PartnersList/>
                    }
                />
                <Route
                    path="/partenaires/:citysubDomain/:categorysubDomain/:partnersubDomain"
                    element={
                        <Partner/>
                    }
                />
                <Route
                    path="/decouverte"
                    element={
                        <CityRoute>
                            <PracticalGuideRedirect/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/index-rues"
                    element={
                        <CityRoute>
                            <StreetsIndex/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/infos-pratiques"
                    element={
                        <CityRoute>
                            <PracticalInfos/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/meteo"
                    element={
                        <CityRoute>
                            <Weather/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/webcam"
                    element={
                        <CityRoute>
                            <LiveCam/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/actualites"
                    element={
                        <CityRoute>
                            <NewsCity/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/magazine-de-la-ville"
                    element={
                        <CityRoute>
                            <MagCity/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/visites-guidees"
                    element={
                        <CityRoute>
                            <GuidedTourList/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/visite-guidee"
                    element={
                        <CityRoute>
                            <GuidedTour/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <CityRoute>
                            <Contact/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/GuidePrestige"
                    element={
                        <CityRoute>
                            <GuidePrestige/>
                        </CityRoute>
                    }
                />
                <Route
                    path="/politique-confidentialité"
                    element={
                        <CityRoute>
                            <PrivacyPolicy/>
                        </CityRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
