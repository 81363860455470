import React from "react";
import logoLBP from "../assets/images/logo-les-bons-plans.png";

const LogoLbp = () => {
  return (
    <div className="logo-lbp-container">
      <img src={logoLBP} alt="Logo Les Bons Plans" />
    </div>
  );
};

export default LogoLbp;
