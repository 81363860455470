import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const AdminSortingPartnerCategorySelect = ({ onChange, value }) => {
  const dataCategory = useSelector((state) => state.categoryReducer);
  const [optionsCategory, setOptionsCategory] = useState([{}]);

  useEffect(() => {
    let tempOptionsCategory = [];
    dataCategory.forEach((category) => {
      tempOptionsCategory.push({ value: category.name, label: category.name });
    });
    tempOptionsCategory.sort((a, b) => a.label.localeCompare(b.label));
    setOptionsCategory(tempOptionsCategory);
  }, [dataCategory]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      flex: "1",
      minWidth: "200px",
      height: "50px",
      margin: "20px 0",
      padding: "5px",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
        cursor: "text",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#b1852d",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
  };
  return (
    <Select
      isMulti
      isClearable
      options={optionsCategory}
      onChange={onChange}
      value={value}
      styles={customStyles}
      placeholder="Catégorie"
    />
  );
};

export default AdminSortingPartnerCategorySelect;
