import React from "react";
import { isEmpty } from "../assets/Utils";

const PopupPartnerContent = ({ partner, closePopup }) => {
  return (
    <div className="popup popup-content-partner">
      <div className="popup-content-partner-container">
        <div className="popup-content-partner-header">
          <img
            src={
              "https://api.lesbonsplans.pro" +
              "/upload/partner/image/" +
              partner.logo
            }
            alt={"Logo " + partner.name}
          />
          <i onClick={() => closePopup({})} className="bx bx-x-circle"></i>
        </div>
        {partner.contentText && (
          <div className="popup-content-partner-text">
            <h3>{partner.name}</h3>
            <p>{partner.contentText}</p>
          </div>
        )}
        {!isEmpty(partner.contentUploads) && (
          <div className="popup-content-partner-uploads">
            {partner.contentUploads.map((upload) => (
              <div key={upload.id} className="popup-content-uploads-container">
                {upload.type === "picture" && (
                  <img
                    src={
                      "https://api.lesbonsplans.pro" +
                      "/upload/partner/image/" +
                      upload.id
                    }
                    alt={"Image " + partner.name}
                  />
                )}
                {upload.type === "video" && (
                  <video controls>
                    <source
                      src={
                        "https://api.lesbonsplans.pro" +
                        "/upload/partner/video/" +
                        upload.id
                      }
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupPartnerContent;
