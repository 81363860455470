import React, {useState} from "react";
import Header from "../layouts/Header";
import {useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import ImageGallery from "../components/ImageGallery";

const Magazines = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dataMagazine = useSelector((state) => state.magazineReducer);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    const handleMagazineClick = (mag) => {
        setSelectedImages(mag.pictures.map(picture => ({
            url: `https://api.lesbonsplans.pro/upload/partner/image/${picture.id}`
        })));
        setGalleryOpen(true);
    };

    return (
        <div className="magazines-container" style={cityBackground}>
            <Header navPath={"magazines"} stringBtn={"Retourner aux magazines"}/>
            <h1>Tous nos magazines</h1>
            <section className="magazine-overview">
                {!isEmpty(contentCurrentCity.magazines) &&
                    Array.isArray(dataMagazine) &&
                    dataMagazine
                        .filter((mag) => contentCurrentCity.magazines.includes(mag._id))
                        .map((mag) => (
                            <div
                                key={mag._id}
                                className="magazine"
                                onClick={() => handleMagazineClick(mag)}
                                style={{
                                    backgroundImage: `url(https://api.lesbonsplans.pro/upload/upload/image/${mag.pictures[0]?.id})`,
                                }}
                            >
                                <div className="name-mag">
                                <p>{mag.name}</p>
                                <p>{mag.numberMag}</p>
                                </div>
                            </div>
                        ))}
                {galleryOpen && (
                    <ImageGallery
                        images={selectedImages}
                        onClose={() => setGalleryOpen(false)}
                    />
                )}
            </section>
        </div>
    );
};

export default Magazines;