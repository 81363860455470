import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setCurrentCity } from "../actions/city.action";

const Logo = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const dispatch = useDispatch();
    const { citysubDomain } = useParams();

    useEffect(() => {
        const fetchCity = async () => {
            try {
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${citysubDomain}`);
                const data = await response.json();
                dispatch(setCurrentCity(data));
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        if (!currentCity.logo && citysubDomain) {
            fetchCity();
        }
    }, [currentCity.logo, citysubDomain, dispatch]);

    return (
        <div className="logo">
            <img
                src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/city/image/" +
                    currentCity.logo
                }
                alt="Logo Les-Bons-Plans"
            />
        </div>
    );
};

export default Logo;