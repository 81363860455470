import React from "react";
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";
import AdminNewUser from "../layouts/AdminNewUser";
import { isEmpty } from "../assets/Utils";
import { useSelector } from "react-redux";

const AdminUsers = () => {
  const dataUser = useSelector((state) => state.userReducer);
  const dataCity = useSelector((state) => state.cityReducer);

  return (
    <div className="user-admin-container">
      <HeaderAdmin />
      <section className="admin-content-container">
        <NavbarAdmin />
        <div className="admin-content">
          <AdminNewUser />
          <section className="user-management">
            <h2>Gestion des utilisateurs</h2>
            <table className="users-table">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Nom d'utilisateur</th>
                  <th>Villes attitrées</th>
                  <th>Niveaux d'administration</th>
                  <th>Modifications</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(dataUser) &&
                  dataUser.map((user) => (
                    <tr key={user._id}>
                      <td>{user.lastName}</td>
                      <td>{user.firstName}</td>
                      <td>{user.userName}</td>
                      <td>
                        {user.assignedCities
                          .map(
                            (cityId) =>
                              dataCity.find((city) => city._id === cityId)
                                .acronym
                          )
                          .join(", ")}
                      </td>
                      <td>
                        {user.adminLevels
                          .map((level) => {
                            switch (level) {
                              case 1:
                                return "Global";
                              case 2:
                                return "Villes";
                              case 3:
                                return "Contenu ville";
                              case 4:
                                return "Modif contenu";
                              case 5:
                                return "Partenaires";
                              case 6:
                                return "Modif partenaires";
                              case 20:
                                return "Notifications";
                              case 30:
                                return "Statistiques";
                              default:
                                return "";
                            }
                          })
                          .join(", ")}
                      </td>
                      <td>
                        <a>Modifier</a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </section>
        </div>
      </section>
    </div>
  );
};

export default AdminUsers;
