import React from "react";
import Select from "react-select";
import {useSelector} from "react-redux";

const AdminSortingCountrySelect = ({onChange, value}) => {
    const dataCity = useSelector((state) => state.cityReducer);

    const countryOptions = Array.from(new Set(dataCity.map(city => city.country)))
        .map(country => ({value: country, label: country}));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            flex: "1",
            minWidth: "200px",
            height: "50px",
            margin: "20px 0",
            padding: "5px",
            backgroundColor: "#e2b259",
            border: "2px solid #fff",
            boxShadow: "none",
            transition: "0.2s ease",
            "&:hover": {
                border: "2px solid #ccc",
                cursor: "text",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
            color: state.isFocused ? "white" : "black",
            ":active": {
                backgroundColor: "white",
                color: "black",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black",
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#b1852d",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "white",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: "white",
            "&:hover": {
                backgroundColor: "red",
                color: "white",
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "white",
            transition: "0.2s ease",
            "&:hover": {
                color: "#ccc",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e2b259",
            border: "2px solid white",
            borderRadius: "4px",
            marginTop: "4px",
            zIndex: 9999,
        }),
    };

    return (
        <Select
            isMulti
            name="countries"
            options={countryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onChange}
            value={value}
            styles={customStyles}
            placeholder="Pays"
        />
    );
};

export default AdminSortingCountrySelect;